<div class="preloader-area">
    <div class="spinner">
        <!-- <div class="inner">
            <div class="disc"></div>
            <div class="disc"></div>
            <div class="disc"></div>
        </div> -->
    </div>
    <!-- <div class="spinner">
        <div class="spinner1"></div>
    </div> -->
</div>