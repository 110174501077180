<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + serviceData?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ serviceData?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                    <div class="header-btn">
                        <button routerLink="/contact" class="default-btn rounded-pill"><i
                                [class]="serviceData?.btn.btn_icon"></i><span></span> {{ serviceData?.btn.btn_name
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngFor="let item of serviceData?.mainServices; let index = index">
    <section class="services-area bg-right-shape ptb-100" *ngIf="index % 2 == 0" [id]="item.id_name">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-content it-service-content">
                    <div class="content left-content">
                        <h2>{{ item.title }}</h2>
                        <p>
                            {{ item.description }}
                        </p>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6"
                                *ngFor="let i of item.servicesDetails.services; let index = index">
                                <div class="feature-box" [routerLink]="['/services/service-details', item.id, i.id]"
                                    *ngIf="index < 6">
                                    <i class="bx bxs-badge-check"></i> {{ i.title }}
                                </div>
                            </div>
                        </div>
                        <button [routerLink]="['/service', item.id]" class="default-btn rounded-pill"><i
                                [class]="item.btn.btn_icon"></i>
                            {{
                            item.btn.btn_name }}<span></span></button>
                    </div>
                </div>
                <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                    <div class="image mx-3">
                        <img [src]="item.image" alt="image" class="rounded-1" style="width: 480px;" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="services-area bg-left-shape bg-fbfbfb ptb-100" *ngIf="index % 2 != 0" [id]="item.id_name">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <div class="image" style="text-align: end;">
                        <img [src]="item.image" alt="image" class="rounded-1" style="width: 480px;" />
                    </div>
                </div>
                <div class="services-content it-service-content">
                    <div class="content">
                        <h2 class="fs-2 fw-semibold">{{ item.title }}</h2>
                        <p>
                            {{ item.description }}
                        </p>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6"
                                *ngFor="let i of item.servicesDetails.services; let index = index">
                                <div class="feature-box" [routerLink]="['/services/service-details', item.id, i.id]"
                                    *ngIf="index < 6">
                                    <i class="bx bxs-badge-check"></i> {{ i.title }}
                                </div>
                            </div>
                        </div>
                        <button [routerLink]="['/service', item.id]" class="default-btn rounded-pill"><i
                                [class]="item.btn.btn_icon"></i>
                            {{
                            item.btn.btn_name }}<span></span></button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- <section class="features-area pt-100 pb-70">
    <div class="container">
        <h2 class="fs-2 fw-bold text-center mb-5">Mobile App Services</h2>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one rounded-4">
                    <i class="bx bx-conversation bg-13c4a1"></i>
                    <h3 class="fs-3" style="font-weight: 600;">UI / UX</h3>
                    <p class="text-black">
                        We Providing android app development native (Java & Kotlin) and cross-platform based on Google
                        Flutter technology.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one rounded-4">
                    <i class="bx bx-conversation bg-13c4a1"></i>
                    <h3 class="fs-3" style="font-weight: 600;">UI / UX</h3>
                    <p class="text-black">
                        We Providing android app development native (Java & Kotlin) and cross-platform based on Google
                        Flutter technology.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one rounded-4">
                    <i class="bx bx-conversation bg-13c4a1"></i>
                    <h3 class="fs-3" style="font-weight: 600;">UI / UX</h3>
                    <p class="text-black">
                        We Providing android app development native (Java & Kotlin) and cross-platform based on Google
                        Flutter technology.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one rounded-4">
                    <i class="bx bx-conversation bg-13c4a1"></i>
                    <h3 class="fs-3" style="font-weight: 600;">UI / UX</h3>
                    <p class="text-black">
                        We Providing android app development native (Java & Kotlin) and cross-platform based on Google
                        Flutter technology.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="team-area ptb-100 bg-fbfbfb">
    <div class="container">
        <h2 class="fs-2 fw-bold text-center mb-5">Mobile App Services</h2>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image border-0 p-0">
                        <img src="assets/img/team-image/img5.jpg" alt="image" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image border-0 p-0">
                        <img src="assets/img/team-image/img5.jpg" alt="image" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image border-0 p-0">
                        <img src="assets/img/team-image/img5.jpg" alt="image" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image border-0 p-0">
                        <img src="assets/img/team-image/img5.jpg" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></button>
        </div>
    </div>
</section>