<div class="main-banner main-banner-one">
    <!-- style="
        background: url('assets/img/bg-home.png') no-repeat bottom center;
        background-size: cover;
    "> -->
    <div class="container-fluid h-100">
        <!-- <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h2 class="fs-1 fw-bolder" style="font-weight: 600;">
                                    {{ homeData?.title }}
                                </h2>
                                <p class="fw-medium">
                                    {{ homeData?.description }}
                                </p>
                                <a routerLink="/contact" class="default-btn rounded-pill"><i
                                        [class]="homeData?.btn.btn_icon"></i><span></span>
                                    {{ homeData?.btn.btn_name}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 h-100">
                <div class="mbanner-bg-one">
                    <div class="d-flex align-items-center justify-content-center h-100 w-100">
                        <img src="/assets/img/home/cover.png" class="mbanner-img" alt="image" />
                    </div>
                </div>
            </div>
        </div> -->

        <div class="swiper-container">
            <div class="swiper-wrapper" data-swiper-autoplay="25000">
                <div class="swiper-slide" *ngFor="let item of serviceData?.mainServices">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="services-content it-service-content order-1">
                                <div class="content left-content">
                                    <h2 class="fs-1 fw-bolder" style="font-weight: 600;">
                                        {{ homeData?.title }}
                                    </h2>
                                    <p class="fw-medium">
                                        {{ homeData?.description }}
                                    </p>
                                    <button routerLink="/contact" class="default-btn rounded-pill"><i
                                            [class]="homeData?.btn.btn_icon"></i><span></span>
                                        {{ homeData?.btn.btn_name}}
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-12 h-100 order-2">
                                <div class="mbanner-bg-one">
                                    <div class="d-flex align-items-center justify-content-center h-100 w-100">
                                        <img src="/assets/img/home/cover.png" class="mbanner-img" alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-scrollbar d-none"></div>
        </div>
    </div>
</div>

<div class="features-card-section pt-100 pb-70 bg-f0f2f5">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of serviceData?.mainServices"
                class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s"
                [routerLink]="['/service', item.id]">
                <div class="single-features-card tx-center">
                    <i [inlineSVG]="item?.icon"></i>
                    <h3>
                        <a [routerLink]="['/service', item.id]">{{ item?.title }}</a>
                    </h3>
                    <p>
                        {{ item?.subTitles }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="swiper-container mt-3">
        <div class="swiper-wrapper" data-swiper-autoplay="25000">
            <div class="swiper-slide" *ngFor="let item of serviceData?.mainServices">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="services-content it-service-content">
                            <div class="content left-content">
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.description }}</p>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6"
                                        *ngFor="let i of item.servicesDetails.services; let index = index">
                                        <div class="feature-box"
                                            [routerLink]="['/services/service-details', item.id, i.id]"
                                            *ngIf="index < 6">
                                            <i class="bx bxs-badge-check"></i> {{ i.title }}
                                        </div>
                                    </div>
                                </div>
                                <button [routerLink]="['/service', item.id]" class="default-btn rounded-pill">
                                    <i [class]="item.btn.btn_icon"></i>
                                    {{ item.btn.btn_name }}
                                    <span></span>
                                </button>
                            </div>
                        </div>
                        <div class="services-image wow animate__animated animate__fadeInRight content right-content col-lg-5 col-md-12"
                            data-wow-delay=".3s">
                            <div class="image d-flex justify-content-center">
                                <img [src]="item.image" alt="image" style="width: 450px;" class="mx-lg-auto px-lg-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="swiper-scrollbar d-none"></div> -->
        <!-- <div class="swiper-pagination">bbbffff</div> Pagination dots will be displayed here -->
        <!-- <div class="swiper-scrollbar d-none">fffff</div> -->
    </div>
</div>

<section class="services-area bg-right-shape ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content it-service-content">
                <div class="content left-content">
                    <!-- <h2>Our mission is to transform innovative ideas into reality.</h2> -->
                    <span>{{ homeData?.about.note }}</span>
                    <h2 class="fs-2">{{ homeData?.about.title }}</h2>
                    <p style="white-space: pre-line;">
                        {{ homeData?.about.description }}
                    </p>
                </div>
            </div>
            <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image">
                    <img [src]="homeData?.about.image" alt="image" class="rounded-1" style="width: 640px;" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="text-center fs-2 mb-3" style="font-weight: 600; font-size: 30px;">
                {{ homeData?.feature.title }}
            </h2>
            <p class="text-center lh-lg fw-normal" style="font-size: 14px;">
                {{ homeData?.feature.description }}
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of homeData?.feature.features">
                <div class="features-box-one wow animate__animated animate__fadeInLeft rounded-8" data-wow-delay=".2s">
                    <i [class]="item?.class" [inlineSVG]="item?.svg"></i>
                    <h3>{{ item?.title }}</h3>
                    <p>
                        {{ item?.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area pt-100 pb-70 bg-f0f2f5">
    <div class="container">
        <div class="section-title">
            <h2 class="text-center fs-2 mb-3" style="font-weight: 600; font-size: 30px;">
                {{ homeData?.clients.title }}
            </h2>
            <p class="text-center lh-lg fw-normal" style="font-size: 14px;">
                {{ homeData?.clients.description }}
                <br>
                {{ homeData?.clients.description_2 }}
            </p>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow animate__animated animate__fadeInUp"
                *ngFor="let item of homeData?.clients?.logos" data-wow-delay=".2s">
                <a>
                    <img [src]="item" alt="image" class="rounded-1" />
                </a>
            </div>
        </div>
    </div>
</section>

<section class="features-area pt-100">
    <div class="container">
        <div class="section-title">
            <h2 class="text-center fs-2 mb-3" style="font-weight: 600;">
                {{homeData?.portfolio.title}}
            </h2>
            <p class="text-center lh-lg fw-normal" style="font-size: 14px;">
                {{homeData?.portfolio.description}}
                <br>
                {{homeData?.portfolio.description_2}}
            </p>
        </div>
        <div class="row">
            <!-- <div class="col-lg-4 col-sm-12 my-3" *ngFor="let item of portfolioData$">
                <div class="image-2 w-100">
                    <img class="rounded-1 cursor-pointer w-100 object-fit-cover" [src]="item.image"
                        sizes="(max-width: 396px) 100vw, 396px" [routerLink]="['/project', item.id]">
                    <div class="overlay">
                        <div class="overlay-content">
                            <div class="overlay-content">
                                <h6 class="text-dark fw-bold">{{ item?.title }}</h6>
                                <div class="fs-normal" style="font-size: 12px;">{{ item?.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of portfolioData$">
                <div class="image-2 w-100 mb-5" [routerLink]="['/project', item.id]">
                    <img class="rounded-1 cursor-pointer w-100 object-fit-cover" [src]="item?.image"
                        sizes="(max-width: 396px) 100vw, 396px" [routerLink]="['/project', item.id]">
                    <!-- <div class="overlay"> -->
                    <div class="text-center my-4">
                        <h6 class="text-dark fw-bold">{{ item?.title }}</h6>
                        <div class="fs-normal px-3 description mb-2" style="font-size: 12px; color: #4a6f8a;">{{
                            item?.description }}
                        </div>
                        <div [routerLink]="['/project', item.id]"
                            class="read-more d-flex align-items-center justify-content-center" style="font-size: 13px;">
                            {{
                            item?.btn.btn_name }} <i [class]="item?.btn.btn_icon" class="mx-1"></i>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button routerLink="/portfolio" class="default-btn rounded-pill"><i
                        [class]="homeData?.portfolio.btn.btn_icon"></i>
                    {{
                    homeData?.portfolio.btn.btn_name }}<span></span></button>
            </div>
        </div>
    </div>
</section>


<!-- /*********** Gallery ***************/ -->

<!-- <section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="text-center fs-2 mb-3" style="font-weight: 600; font-size: 30px;">
                {{ homeData.gellary.title }}
            </h2>
            <p class="text-center lh-lg fw-normal" style="font-size: 14px;">
                {{ homeData.gellary.description }}
                <br>
                {{homeData?.gellary.description_2}}
            </p>
        </div>
        <div class="text-center row gap-2 gap-lg-0">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <img [src]="homeData.gellary.images[0]" class="rounded-1 w-100">
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="d-flex flex-column justify-content-between align-items-center h-100 gap-2">
                    <span class="rounded-1 w-100">
                        <img [src]="homeData.gellary.images[1]" class="rounded-1 w-100 mb-xl-0 object-fit-cover"
                            style="height: 192px;">
                    </span>
                    <span class="rounded-1 w-100">
                        <img [src]="homeData.gellary.images[2]" class="rounded-1 w-100 my-xl-0 object-fit-cover"
                            style="height: 192px;">
                    </span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <img [src]="homeData.gellary.images[3]" class="rounded-1 w-100">
            </div>
        </div>
    </div>
</section> -->

<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></button>
        </div>
    </div>
</section>