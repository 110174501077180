import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";

import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { ProtofolioComponent } from "./components/pages/protofolio/protofolio.component";
import { ServiceDetailsComponent } from "./components/pages/service-details/service-details.component";
import { Project1Component } from "./components/pages/project-1/project-1.component";

const routes: Routes = [
    { path: "", component: HomeOneComponent },
    { path: "about", component: AboutComponent },
    { path: "pricing", component: PricingComponent },
    { path: "services", component: ServicesOneComponent },
    {
        path: "services/service-details/:id/:serviceId",
        component: ServicesDetailsComponent,
    },
    { path: "team", component: TeamComponent },
    { path: "error", component: ErrorComponent },
    { path: "blog", component: BlogGridComponent },
    { path: "blog-2", component: BlogRightSidebarComponent },
    { path: "blog-details/:id", component: BlogDetailsComponent },
    { path: "contact", component: ContactComponent },
    { path: "terms-conditions", component: TermsConditionsComponent },
    { path: "privacy", component: PrivacyPolicyComponent },
    { path: "portfolio", component: ProtofolioComponent },
    { path: "service/:id", component: ServiceDetailsComponent },
    { path: "project/:id", component: Project1Component },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            anchorScrolling: "enabled",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
