import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { Subject, Subscription, takeUntil } from "rxjs";
import { TranslationService } from "src/assets/i18n";
import { ServicesService } from "src/app/services/services.service";
import * as $ from "jquery";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class NavbarComponent implements OnInit {
    headerData: any;
    serviceData: any;
    private unsubscribe: Subscription[] = [];

    langList: any[] = [
        {
            name: "English",
            code: "en",
            flag: "/assets/img/icons/UKFlag.png",
        },
        {
            name: "عربي",
            code: "ar",
            flag: "/assets/img/icons/SAFlag.png",
        },
    ];
    location: any;
    navbarClass: any;
    companyName: string = "Orial";
    selectedLang: any;
    dropdownOpen: boolean = false;
    constructor(
        private router: Router,
        location: Location,
        private translationService: TranslationService,
        private services: ServicesService,
        private cdf: ChangeDetectorRef
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.location = this.router.url;
                if (
                    this.location == "/" ||
                    this.location == "/home-2" ||
                    this.location == "/home-3" ||
                    this.location == "/home-5" ||
                    this.location == "/home-6" ||
                    this.location == "/home-7" ||
                    this.location == "/without-animation-home-1" ||
                    this.location == "/without-animation-home-2" ||
                    this.location == "/without-animation-home-3" ||
                    this.location == "/without-animation-home-5" ||
                    this.location == "/without-animation-home-6" ||
                    this.location == "/without-animation-home-7"
                ) {
                    this.navbarClass = "navbar-area";
                } else if (
                    this.location == "/home-4" ||
                    this.location == "/without-animation-home-4"
                ) {
                    this.navbarClass = "navbar-area bg-white";
                } else {
                    this.navbarClass = "navbar-area navbar-style-two";
                }
            }
        });
    }

    ngOnInit() {
        this.services.getGeneralData();
        this.services.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                (this.headerData = data?.header),
                    (this.serviceData = data?.data.mainServices),
                    this.cdf.detectChanges();
            });
        this.selectedLang =
            this.translationService.getSelectedLanguage() == "en"
                ? this.langList[0]
                : this.langList[1];
    }
    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    selectLang(code: string) {
        this.selectedLang = code;
        this.dropdownOpen = false;
        this.selectLanguage();
    }
    selectLanguage() {
        const newLang = this.currentLang() === "en" ? "ar" : "en";
        // Display preloader
        $(".preloader-area").fadeIn("fast");
        $(".preloader-area").fadeOut("slow");
        window.location.reload();
        this.translationService.setLanguage(newLang);
    }

    currentLang() {
        return this.translationService.getSelectedLanguage();
    }

    navigateToSection(sectionId: any) {
        this.router.navigate(["/services"], { fragment: sectionId });
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    closeNavbar() {
        // Check if the screen width is less than or equal to 768 pixels (small screen)
        const navbarToggler = document.querySelector(
            ".navbar-toggler"
        ) as HTMLElement;
        const navbarCollapse = document.getElementById(
            "navbarSupportedContent"
        );

        if (navbarCollapse?.classList.contains("show")) {
            navbarToggler.click(); // Trigger the collapse
        }
        this.showSubMenu = false;
        this.currentSubMenu = "";
        this.currentServiceDetails = [];
    }

    currentSubMenu = "";
    currentServiceDetails: any[] = [];
    showSubMenu = false;
    menuHistory: { menu: string; details: any[] }[] = [];

    openSubMenu(menu: string, servicesDetails: any[]) {
        this.menuHistory.push({
            menu: this.currentSubMenu,
            details: this.currentServiceDetails,
        });
        this.currentSubMenu = menu;
        this.currentServiceDetails = servicesDetails;
        this.showSubMenu = true;
    }

    closeSubMenu() {
        if (this.menuHistory.length > 0) {
            const previousState = this.menuHistory.pop();
            this.currentSubMenu = previousState.menu;
            this.currentServiceDetails = previousState.details;
            this.showSubMenu = this.currentSubMenu !== "";
        } else {
            this.showSubMenu = false;
            this.currentSubMenu = "";
            this.currentServiceDetails = [];
        }
    }
    closeAllMenus() {
        this.closeSubMenu();
        this.menuHistory = []; // Clear history when closing all menus
    }
}
