<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + aboutData?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ aboutData?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                    <div class="header-btn">
                        <button routerLink="/contact" class="default-btn rounded-pill"><i
                                [class]="aboutData?.btn.btn_icon"></i><span></span> {{ aboutData?.btn.btn_name
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title my-2">{{aboutData?.about.title}}</span>
                    <h2>{{aboutData?.about.subTitle}}</h2>
                    <p style="white-space: pre-line;">
                        {{aboutData?.about.description}}
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img [src]="aboutData?.about.image" alt="image" class="rounded-1" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-md-2">
                <div class="faq-accordion mx-0">
                    <h2 class="fs-2 my-xl-3 my-3" style="font-weight: 600;">{{aboutData?.about.title}}</h2>
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    {{aboutData?.fqs.fqs[0].question}}
                                    <i class="bx bx-plus"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse show"
                                data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    {{aboutData?.fqs.fqs[0].answer}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {{aboutData?.fqs.fqs[1].question}}
                                    <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    {{aboutData?.fqs.fqs[1].answer}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    {{aboutData?.fqs.fqs[2].question}}
                                    <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    {{aboutData?.fqs.fqs[2].answer}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    {{aboutData?.fqs.fqs[3].question}}
                                    <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    {{aboutData?.fqs.fqs[3].answer}}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    {{aboutData?.fqs.fqs[4].question}}
                                    <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    {{aboutData?.fqs.fqs[4].answer}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 order-md-1">
                <div class="faq-image wow animate__animated animate__fadeInUp faq-accordion" data-wow-delay=".2s">
                    <img [src]="aboutData?.fqs.image" alt="image" class="rounded-1" />
                </div>
            </div>
        </div>
    </div>
</section>


<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="fs-2" style="font-weight: 600;">{{aboutData?.video.title}}</h2>
        </div>
        <div class="video-box">
            <img [src]="aboutData?.video.image" class="main-image" alt="image" />
            <a [href]="aboutData?.video.video_url" target="_blank" class="video-btn popup-youtube"><i
                    class="bx bx-play"></i></a>
            <div class="shape1">
                <img src="assets/img/shape/shape1.png" alt="image" />
            </div>
            <div class="shape2">
                <img src="assets/img/shape/shape2.png" alt="image" />
            </div>
            <div class="shape3">
                <img src="assets/img/shape/shape3.png" alt="image" />
            </div>
            <!-- <div class="shape4">
                <img src="assets/img/shape/shape4.png" alt="image" />
            </div> -->
            <!-- <div class="shape5">
                <img src="assets/img/shape/shape5.png" alt="image" />
            </div> -->
            <div class="shape6">
                <img src="assets/img/shape/shape6.png" alt="image" />
            </div>
        </div>
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft"
                        data-wow-delay=".2s">
                        <i class="bx bx-list-check"></i>
                        <h3>
                            <span class="odometer" data-count="59"></span><span class="sign-icon">+</span>
                        </h3>
                        <p>{{aboutData?.projects[0].title}}</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft"
                        data-wow-delay=".4s">
                        <i class="bx bx-smile"></i>
                        <h3>
                            <span class="odometer" data-count="44"></span><span class="sign-icon">+</span>
                        </h3>
                        <p>{{aboutData?.projects[1].title}}</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft"
                        data-wow-delay=".6s">
                        <i class="bx bx-grid-small"></i>
                        <h3>
                            <span class="odometer" data-count="5"></span><span class="sign-icon">+</span>
                        </h3>
                        <p>{{aboutData?.projects[2].title}}</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay=".8s">
                    <div class="single-funfacts funfact-style-two">
                        <i class="bx bxs-award"></i>
                        <h3>
                            <span class="odometer" data-count="3"></span><span class="sign-icon">+</span>
                        </h3>
                        <p>{{aboutData?.projects[3].title}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image" /></div>
    <div class="shape7">
        <img src="assets/img/shape/shape7.png" alt="image" />
    </div>
    <div class="shape8">
        <img src="assets/img/shape/shape8.png" alt="image" />
    </div>
    <div class="shape9">
        <img src="assets/img/shape/shape9.png" alt="image" />
    </div>
</section>


<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></button>
        </div>
    </div>
</section>