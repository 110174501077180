import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";
import Swal from "sweetalert2";
import Swiper from "swiper";

@Component({
    selector: "app-services-details",
    templateUrl: "./services-details.component.html",
    styleUrls: ["./services-details.component.scss"],
})
export class ServicesDetailsComponent implements OnInit  {
    id: any;
    serviceId: any;
    servicesDetailsById: any;
    servicesData: any;
    contact_section: any;

    file: any;
    fileUrl: string;
    contactForm: FormGroup;
    isLoading = false;
    protfolioForSubService: any;

    _unsubscribeAll: Subject<any> = new Subject();

    private intervalId: any;
    private swiperSections: Swiper;
    private swiperProjects: Swiper;

    sizePageLg: boolean = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private services: ServicesService,
        private cdf: ChangeDetectorRef,
        private http: HttpClient,
        private fb: FormBuilder,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.initForm();
        this.activatedRoute.paramMap
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((paramMap) => {
                this.id = paramMap.get("id");
                this.serviceId = paramMap.get("serviceId");
                this.fetchServicesData();
            });

    }

    // fetchServicesData() {
    //     this.services.getServices();
    //     this.services.getServicesById(this.id, this.serviceId);
    //     this.services.servicesDetailsById
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe((servicesDetailsById) => {
    //             (this.servicesDetailsById = servicesDetailsById?.details),
    //                 (this.servicesData = servicesDetailsById);
    //             this.fetchProtofolioData();
    //             // this.contactForm
    //             //     .get("ServiceId")
    //             //     .setValue(this.servicesData.unique_id);
    //             this.cdf.detectChanges();
    //         });
    //     this.services.mainServicesData
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe((mainServicesData) => {
    //             this.contactForm
    //                 .get("ServiceId")
    //                 .setValue(mainServicesData?.unique_id);
    //         });
    //     this.services.contact_section
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe((contact_section) => {
    //             (this.contact_section = contact_section),
    //                 this.cdf.detectChanges();
    //         });
    // }

    // fetchProtofolioData() {
    //     this.services.getPortfolioBySubService(
    //         mainServicesData?.unique_id,
    //         this.servicesData?.unique_id
    //     );
    //     this.services.portfolioForSubService
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe((protfolioForSubService) => {
    //             (this.protfolioForSubService = protfolioForSubService),
    //                 this.cdf.detectChanges();
    //         });
    // }

    fetchServicesData() {
        this.services.getServices();
        this.services.getServicesById(this.id, this.serviceId);

        this.services.servicesDetailsById
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((servicesDetailsById) => {
                this.servicesDetailsById = servicesDetailsById?.details;
                this.servicesData = servicesDetailsById;
                this.fetchProtofolioData();
                this.cdf.detectChanges();
            });

        this.services.mainServicesData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((mainServicesData) => {
                if (mainServicesData) {
                    this.contactForm
                        .get("ServiceId")
                        .setValue(mainServicesData?.unique_id);
                }
            });
        this.services.contact_section
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((contact_section) => {
                this.contact_section = contact_section;
                this.cdf.detectChanges();
            });
    }

    fetchProtofolioData() {
        const mainServiceId = this.services.mainServicesData?.value?.unique_id;
        const subServiceId =
            this.services.servicesDetailsById?.value?.unique_id;

        if (mainServiceId && subServiceId) {
            this.services.getPortfolioBySubService(mainServiceId, subServiceId);

            this.services.portfolioForSubService
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((portfolioForSubService) => {
                    this.protfolioForSubService = portfolioForSubService;
                    this.cdf.detectChanges();
                });
        }
    }

    initForm() {
        this.contactForm = this.fb.group({
            Name: ["", [Validators.required, Validators.minLength(3)]],
            Email: [
                "",
                [
                    Validators.required,
                    Validators.email,
                    Validators.minLength(3),
                ],
            ],
            PhoneNumber: ["", Validators.required],
            Message: ["", [Validators.required, Validators.minLength(3)]],
            Subject: ["", [Validators.required, Validators.minLength(3)]],
            TypeId: [1],
            ServiceId: ["", Validators.required],
        });
    }

    onFileSelected(event: any) {
        this.file = event.target.files[0];
        if (this.file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.fileUrl = e.target.result;
                this.cdf.detectChanges();
            };
            reader.readAsDataURL(this.file);
        }
    }

    // submit() {
    //     let formBody = this.contactForm.value;
    //     let formData = new FormData();

    //     if (this.file) {
    //         formData.append("FileURL", this.file);
    //     }

    //     for (let outerKey in formBody) {
    //         if (formBody[outerKey] !== null) {
    //             formData.append(outerKey, formBody[outerKey]);
    //         }
    //     }
    //     if (this.contactForm.valid) {
    //         this.http
    //             .post(
    //                 "https://shipping-system-apis.orial-eg.com/api/v1/ContactUs/Create",
    //                 formData
    //             )
    //             .subscribe({
    //                 next: (res: any) => {
    //                     Swal.fire({
    //                         icon: "success",
    //                         text: this.translate.instant("GENERAL.MSG_SUCCESS"),
    //                         showConfirmButton: false,
    //                         showCloseButton: true,
    //                         timer: 2000,
    //                     });
    //                     this.contactForm.reset();
    //                     this.fileUrl = null || "";
    //                     this.cdf.detectChanges();
    //                 },
    //             });
    //     } else {
    //         Swal.fire({
    //             icon: "error",
    //             text: this.translate.instant("GENERAL.MSG_ERROR"),
    //             showCloseButton: true,
    //             focusCancel: false,
    //             showConfirmButton: false,
    //         });
    //     }
    // }

    submit() {
        this.isLoading = true;
        let formBody = this.contactForm.value;
        let formData = new FormData();

        if (this.file) {
            formData.append("FileURL", this.file);
        }

        for (let outerKey in formBody) {
            if (formBody[outerKey] !== null) {
                formData.append(outerKey, formBody[outerKey]);
            }
        }

        if (this.contactForm.valid) {
            this.http
                .post(
                    "https://website.orial-eg.com/api/v1/ContactUs/Create",
                    formData
                )
                .subscribe({
                    next: (res: any) => {
                        Swal.fire({
                            icon: "success",
                            text: this.translate.instant("GENERAL.MSG_SUCCESS"),
                            showCloseButton: true,
                            focusCancel: false,
                            showConfirmButton: false,
                            buttonsStyling: false, // Disable default styling
                            customClass: {
                                popup: "custom-popup-class",
                                title: "custom-title-class",
                                htmlContainer: "custom-content-class",
                                footer: "custom-footer-class",
                                confirmButton: "custom-confirm-button-class",
                            },
                        });
                        this.resetForm();
                        this.isLoading = false; // Set isLoading to false on success
                    },
                    error: (err: any) => {
                        this.isLoading = false; // Set isLoading to false on error
                        Swal.fire({
                            icon: "error",
                            text: this.translate.instant("GENERAL.MSG_ERROR"),
                            showCloseButton: true,
                            focusCancel: false,
                            showConfirmButton: false,
                            buttonsStyling: false, // Disable default styling
                            customClass: {
                                popup: "custom-popup-class",
                                title: "custom-title-class",
                                htmlContainer: "custom-content-class",
                                footer: "custom-footer-class",
                                confirmButton: "custom-confirm-button-class",
                            },
                        });
                    },
                    complete: () => {
                        this.isLoading = false; // Ensure isLoading is set to false on completion
                    },
                });
        } else {
            this.isLoading = false; // Set isLoading to false if form is invalid
            Swal.fire({
                icon: "error",
                text: this.translate.instant("GENERAL.MSG_ERROR"),
                showCloseButton: true,
                focusCancel: false,
                showConfirmButton: false,
                buttonsStyling: false, // Disable default styling
                customClass: {
                    popup: "custom-popup-class",
                    title: "custom-title-class",
                    htmlContainer: "custom-content-class",
                    footer: "custom-footer-class",
                    confirmButton: "custom-confirm-button-class",
                },
            });
        }
    }

    resetForm() {
        this.contactForm.reset();
        this.contactForm.patchValue({ TypeId: 1 });
        this.fetchServicesData();
        this.fileUrl = "";
        this.isLoading = false;
        this.cdf.detectChanges();
    }


    ngAfterViewInit(): void {
        this.updateSwiperDirection();
        // this.getSwiper();
    }

    getSwiper() {
        this.swiperSections = new Swiper(".swiperSections", {
            direction: "horizontal",
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: 3000, // Ensure this is uncommented
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false,
            },
            slidesPerView: 1,
            spaceBetween: 1,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                },
            },
            on: {
                init: () => {
                    this.startAutoplay();
                },
            },
        });

        this.swiperProjects = new Swiper(".swiperProjects", {
            direction: "horizontal",
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: 3000,
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: true,
            },
            slidesPerView: 1,
            spaceBetween: 2,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 2,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 2,
                },

            },
            on: {
                init: () => {
                    this.startAutoplay();
                },
            },
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.updateSwiperDirection();
    }

    updateSwiperDirection() {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 768) {
        this.sizePageLg = true;
        this.getSwiper();
        // this.swiperSections.changeDirection('vertical');
      } else {
        this.sizePageLg = false;
        this.getSwiper();

      }
    }

    startAutoplay(): void {


        this.intervalId = setInterval(() => {
            this.swiperProjects.slideNext();
            if (this.swiperSections && !this.sizePageLg) {
                this.swiperSections.slideNext();
            }
        }, 3000);
    }



    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
