import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";

@Component({
    selector: "app-blog-details",
    templateUrl: "./blog-details.component.html",
    styleUrls: ["./blog-details.component.scss"],
})
export class BlogDetailsComponent implements OnInit {
    blogId: string;
    private unsubscribe: Subscription[] = [];
    blogData: any;
    contact_section: any;

    constructor(
        private services: ServicesService,
        private cdf: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.paramMap.subscribe((paramMap) => {
            this.blogId = paramMap?.get("id");
        });
    }

    ngOnInit() {
        this.services.getServices();
        this.services.getGeneralData();
        this.services.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                (this.blogData = data.blog), this.cdf.detectChanges();
            });
        this.services.contact_section
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact_section) => {
                (this.contact_section = contact_section),
                    this.cdf.detectChanges();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
