import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";
import { filter } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
    selector: "app-service-details",
    templateUrl: "./service-details.component.html",
    styleUrl: "./service-details.component.scss",
})
export class ServiceDetailsComponent {
    serviceId: any;
    servicesDataById: any;
    contact_section: any;

    file: any;
    fileUrl: string;
    contactForm: FormGroup;

    _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private services: ServicesService,
        private cdf: ChangeDetectorRef,
        private http: HttpClient,
        private fb: FormBuilder,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.initForm();
        this.activatedRoute.paramMap
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((paramMap) => {
                this.serviceId = paramMap?.get("id");
                this.fetchServicesData();
            });
    }

    fetchServicesData() {
        this.services.getServices();
        this.services.getServicesById(this.serviceId);
        this.services.servicesDataById
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((servicesDataById) => {
                (this.servicesDataById = servicesDataById),
                    console.log(this.servicesDataById);
                this.cdf.detectChanges();
            });
        this.services.mainServicesData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((mainServicesData) => {
                this.contactForm
                    .get("ServiceId")
                    .setValue(mainServicesData?.unique_id);
            });
        this.services.contact_section
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((contact_section) => {
                (this.contact_section = contact_section),
                    this.cdf.detectChanges();
            });
    }

    initForm() {
        this.contactForm = this.fb.group({
            Name: ["", Validators.compose([Validators.required])],
            Email: [
                "",
                Validators.compose([Validators.required, Validators.email]),
            ],
            PhoneNumber: ["", Validators.required],
            Message: ["", Validators.required],
            Subject: ["", Validators.required],
            TypeId: [1],
            ServiceId: [""],
        });
    }

    onFileSelected(event: any) {
        this.file = event.target.files[0];
        if (this.file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.fileUrl = e.target.result;
                this.cdf.detectChanges();
            };
            reader.readAsDataURL(this.file);
        }
    }

    submit() {
        let formBody = this.contactForm.value;
        let formData = new FormData();

        if (this.file) {
            formData.append("FileURL", this.file);
        }

        for (let outerKey in formBody) {
            if (formBody[outerKey] !== null) {
                formData.append(outerKey, formBody[outerKey]);
            }
        }
        if (this.contactForm.valid) {
            this.http
                .post(
                    "https://shipping-system-apis.orial-eg.com/api/v1/ContactUs/Create",
                    formData
                )
                .subscribe({
                    next: (res: any) => {
                        Swal.fire({
                            icon: "success",
                            text: this.translate.instant("GENERAL.MSG_SUCCESS"),
                            showConfirmButton: false,
                            showCloseButton: true,
                            timer: 2000,
                        });
                        this.contactForm.reset();
                        this.fileUrl = "";
                        this.cdf.detectChanges();
                    },
                });
        } else {
            Swal.fire({
                icon: "error",
                text: this.translate.instant("GENERAL.MSG_ERROR"),
                showCloseButton: true,
                focusCancel: false,
                showConfirmButton: false,
            });
        }
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
