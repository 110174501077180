import { ChangeDetectorRef, Component, HostListener } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";
import Swiper from "swiper";

@Component({
    selector: "app-project-1",
    templateUrl: "./project-1.component.html",
    styleUrl: "./project-1.component.scss",
})
export class Project1Component {
    id: any;

    private unsubscribe: Subscription[] = [];
    portfolioData: any;
    projectData: any;
    projectDetails: any;
    contact_section: any;

    projects: any;

    _unsubscribeAll: Subject<any> = new Subject();

    protfolioForSubService: any;
    servicesDetailsById: any;

    private swiper: Swiper;
    private swiperFeedback: Swiper;
    private swiperPrpjects: Swiper;

    private intervalId: any;
    products = [
        {
            name: "Product 1",
            image: "bamboo-watch.jpg",
            inventoryStatus: "INSTOCK",
            price: 65,
        },
        {
            name: "Product 2",
            image: "black-watch.jpg",
            inventoryStatus: "LOWSTOCK",
            price: 72,
        },
        {
            name: "Product 3",
            image: "bamboo-watch.jpg",
            inventoryStatus: "INSTOCK",
            price: 65,
        },
        {
            name: "Product 4",
            image: "black-watch.jpg",
            inventoryStatus: "LOWSTOCK",
            price: 72,
        },
        {
            name: "Product 5",
            image: "bamboo-watch.jpg",
            inventoryStatus: "INSTOCK",
            price: 65,
        },
        {
            name: "Product 6",
            image: "black-watch.jpg",
            inventoryStatus: "LOWSTOCK",
            price: 72,
        },
    ];

    constructor(
        private services: ServicesService,
        private activatedRoute: ActivatedRoute,
        private cdf: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.activatedRoute.paramMap
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((paramMap) => {
                this.id = paramMap.get("id");
                this.getData();
                this.fetchProjectData();
                setTimeout(() => {
                    this.getSwiper();

                }, 300);
            });

        console.log(document.dir);

    }

    getData() {
        this.services.getServices();
        this.services.getGeneralData();
        this.services.getAllProjects();
        this.services.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.projectData = data?.project;
                console.log(this.projectData, "projectData");
                this.cdf.detectChanges();
            });
        this.services.contact_section
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((contact_section) => {
                this.contact_section = contact_section;
                this.cdf.detectChanges();
            });
        this.services.projects$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((projects) => {
                this.projects = projects;
                this.cdf.detectChanges();
            });
    }

    fetchProjectData() {
        this.services.getProjectsById(this.id);
        this.services.projectDataById
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((projectDetails) => {
                this.projectDetails = projectDetails;
                console.log(this.projectDetails, "projectDetails");
                this.cdf.detectChanges();
            });
        this.services.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.portfolioData = data?.portfolio;
                this.cdf.detectChanges();
            });

        this.services.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.portfolioData = data?.portfolio;
                this.cdf.detectChanges();
            });
    }

    splitwords(str:any ,operator,index) {

        return str?.split(operator)[index];

    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getSwiper();

        }, 300);

    }

    getSwiper() {
        this.swiper = new Swiper(".swiper1", {

            direction: "horizontal",
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: 3000, // Uncomment if you want autoplay
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false,
            },
            slidesPerView: 3,
            spaceBetween: 3,
            // Responsive breakpoints
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 2,
                    spaceBetween: 2,
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 3,
                    spaceBetween: 3,
                },
            },
            on: {
                init: () => {
                    this.startAutoplay();
                },
            },
        });

        this.swiperFeedback = new Swiper(".swiperFeedback", {
            direction: "horizontal",
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
            },
            // pagination: {
            //     el: ".swiper-pagination",
            //     clickable: true, // Allows clicking on pagination dots to move to respective slide
            //     dynamicBullets: true, // Optional: Adds dynamic sizing to pagination bullets
            // },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: 3000, // Uncomment if you want autoplay
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false,
            },
            slidesPerView: 1,
            spaceBetween: 0,
            on: {
                init: () => {
                    this.startAutoplay();
                },
            },
        });

        this.swiperPrpjects = new Swiper(".swiperPrpjects", {
            direction: "horizontal",
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
            },
            // pagination: {
            //     el: ".swiper-pagination",
            //     clickable: true, // Allows clicking on pagination dots to move to respective slide
            //     dynamicBullets: true, // Optional: Adds dynamic sizing to pagination bullets
            // },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: 3000, // Uncomment if you want autoplay
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false,
            },
            slidesPerView: 1,
            spaceBetween: 2,
            // Responsive breakpoints
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 2,
                    spaceBetween: 2,
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 3,
                    spaceBetween: 2,
                },
            },
            on: {
                init: () => {
                    this.startAutoplay();
                },
            },
        });
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.getSwiper()
    }
    startAutoplay(): void {
        this.intervalId = setInterval(() => {
            this.swiperPrpjects.slideNext();
            if (this.swiper) {
                this.swiper.slideNext();
            }
        }, 2500);
        setInterval(() => {
            if (this.swiper) {
                this.swiperFeedback.slideNext();
            }
        }, 3500);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
