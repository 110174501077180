<div class="{{ navbarClass }} py-0">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <!-- <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo" />
            </a> -->
            <a class="symbol navbar-brand my-2" routerLinkActive="active menu-here" routerLink="/">
                <img alt="Logo" src="assets/img/orialCompany/header.png" class="app-sidebar-logo-default" />
            </a>
            <div class="d-flex align-items-center justify-content-center">
                <button class="navbar-toggler border-0" style="color: #080a3c;" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <!-- <span class="navbar-toggler-icon"></span> -->
                    <svg width="24" height="24" viewBox="0 0 18 18">
                        <polyline id="globalnav-menutrigger-bread-bottom" fill="none" stroke="currentColor"
                            stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" points="2 12, 16 12"
                            class="globalnav-menutrigger-bread globalnav-menutrigger-bread-bottom">
                            <animate id="globalnav-anim-menutrigger-bread-bottom-open" attributeName="points"
                                keyTimes="0;0.5;1" dur="0.24s" begin="indefinite" fill="freeze" calcMode="spline"
                                keySplines="0.42, 0, 1, 1;0, 0, 0.58, 1"
                                values=" 2 12, 16 12; 2 9, 16 9; 3.5 15, 15 3.5"></animate>
                            <animate id="globalnav-anim-menutrigger-bread-bottom-close" attributeName="points"
                                keyTimes="0;0.5;1" dur="0.24s" begin="indefinite" fill="freeze" calcMode="spline"
                                keySplines="0.42, 0, 1, 1;0, 0, 0.58, 1"
                                values=" 3.5 15, 15 3.5; 2 9, 16 9; 2 12, 16 12"></animate>
                        </polyline>
                        <polyline id="globalnav-menutrigger-bread-top" fill="none" stroke="currentColor"
                            stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" points="2 5, 16 5"
                            class="globalnav-menutrigger-bread globalnav-menutrigger-bread-top">
                            <animate id="globalnav-anim-menutrigger-bread-top-open" attributeName="points"
                                keyTimes="0;0.5;1" dur="0.24s" begin="indefinite" fill="freeze" calcMode="spline"
                                keySplines="0.42, 0, 1, 1;0, 0, 0.58, 1" values=" 2 5, 16 5; 2 9, 16 9; 3.5 3.5, 15 15">
                            </animate>
                            <animate id="globalnav-anim-menutrigger-bread-top-close" attributeName="points"
                                keyTimes="0;0.5;1" dur="0.24s" begin="indefinite" fill="freeze" calcMode="spline"
                                keySplines="0.42, 0, 1, 1;0, 0, 0.58, 1" values=" 3.5 3.5, 15 15; 2 9, 16 9; 2 5, 16 5">
                            </animate>
                        </polyline>
                    </svg>
                </button>
            </div>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav d-lg-flex justify-content-lg-center align-items-lg-center w-100">
                    <li class="nav-item" (click)="closeNavbar()">
                        <a routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{
                                        exact: true
                                    }" class="nav-link">{{ headerData?.home }}
                            <!-- <i class="bx bx-chevron-down"></i> -->
                        </a>
                        <!-- <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a href="#" class="nav-link">With Animation Home
                                    <i class="bx bx-chevron-right"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home One (IT Startup)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/home-2" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Two (IT Startup)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/home-3" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Three (IT Startup)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/home-4" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Four (SaaS Startup)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/home-5" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Five (Chatbot)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/home-6" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Six (Chatbot)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/home-7" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Seven (Chatbot)</a>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="#" class="nav-link">Without Animation Home
                                    <i class="bx bx-chevron-right"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/without-animation-home-1" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home One (IT Startup)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/without-animation-home-2" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Two (IT Startup)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/without-animation-home-3" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Three (IT Startup)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/without-animation-home-4" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Four (SaaS Startup)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/without-animation-home-5" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Five (Chatbot)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/without-animation-home-6" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Six (Chatbot)</a>
                                    </li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <a routerLink="/without-animation-home-7" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Home Seven (Chatbot)</a>
                                    </li>
                                </ul>
                            </li>
                        </ul> -->
                    </li>

                    <li class="nav-item">
                        <a routerLink="/services" routerLinkActive="active1"
                            [routerLinkActiveOptions]="{ exact: false }" class="nav-link">{{ headerData?.services }} <i
                                class="bx bx-chevron-down"></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"
                                *ngFor="let item of serviceData">
                                <!-- [routerLink]="['/service', item.id]" -->
                                <a (click)="navigateToSection(item.id_name)" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{
                                                exact: false
                                            }"> {{ item.title }}
                                    <i
                                        [ngClass]="currentLang() === 'en' ? 'bx bx-chevron-right ':'bx bx-chevron-left'"></i>
                                </a>

                                <ul class="dropdown-menu" *ngIf="item.servicesDetails.services.length">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse"
                                        *ngFor="let i of item.servicesDetails.services" (click)="closeNavbar()">
                                        <a class="nav-link" [routerLink]="['/services/service-details', item.id, i.id]"
                                            routerLinkActive="active1" [routerLinkActiveOptions]="{
                                                            exact: false
                                                        }">{{ i.title }} </a>
                                    </li>
                                </ul>

                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse" (click)="closeNavbar()">
                        <a routerLink="/portfolio" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.portfolio }}</a>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse" (click)="closeNavbar()">
                        <a routerLink="/team" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.team }}</a>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse" (click)="closeNavbar()">
                        <a routerLink="/about" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.about }}</a>
                    </li>

                    <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse" (click)="closeNavbar()">
                        <a routerLink="/blog" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                            class="nav-link">{{ headerData?.blog }}</a>
                    </li> -->

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse" (click)="closeNavbar()">
                        <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.contact }}</a>
                    </li>
                </ul>

                <!-- <div class="language-selector d-flex align-items-center justify-content-end">
                    <select class="form-select form-select-sm bg-transparent border-0" [(ngModel)]="selectedLang.code"
                        (change)="selectLanguage()">
                        <option *ngFor="let item of langList" [value]="item?.code">
                            {{ item?.name }}
                        </option>
                    </select>
                </div> -->
                <div class="custom-select mt-2">
                    <div class="selected" (click)="toggleDropdown()">
                        <img class="mx-1" [src]="selectedLang.flag">
                        {{ selectedLang.name }}
                    </div>
                    <div class="options" *ngIf="dropdownOpen">
                        <div *ngFor="let item of langList"
                            [ngClass]="{'selected-option': item.code === selectedLang.code}"
                            (click)="selectLang(item.code)">
                            <img class="mx-1" [src]="item.flag">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="navbar-container h-100" id="navbarSupportedContent">
                    <div *ngIf="!showSubMenu" class="main-menu">
                        <div class="d-flex align-items-center justify-content-between py-3">
                            <div class="object-cover" style="width: 126px;" routerLinkActive="active menu-here"
                                routerLink="/" (click)="closeNavbar()">
                                <img alt="Logo" src="assets/img/orialCompany/header.png"
                                    class="app-sidebar-logo-default" />
                            </div>
                            <button class="close-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <i class='bx bx-x fs-1' style="color: #080a3c;"></i> <!-- Close Icon -->
                            </button>
                        </div>
                        <ul class="fs-3 mx-4" style="margin-top: 5rem;">
                            <li class="nav-item fw-bold mb-2" data-toggle="collapse" data-target=".navbar-collapse"
                                (click)="closeNavbar()">
                                <a routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{
                                    exact: true
                                }" class="nav-link">{{ headerData?.home }}
                                </a>
                            </li>

                            <li class="nav-item fw-bold  mb-2" data-toggle="collapse" data-target=".navbar-collapse"
                                (click)="closeNavbar()">
                                <!-- <a (click)="openSubMenu('service', serviceData)" class="nav-link">{{
                                    headerData?.services }}</a> -->
                                <a routerLink="/services" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.services }}</a>
                            </li>

                            <li class="nav-item fw-bold mb-2" data-toggle="collapse" data-target=".navbar-collapse"
                                (click)="closeNavbar()">
                                <a routerLink="/portfolio" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.portfolio }}</a>
                            </li>

                            <li class="nav-item fw-bold  mb-2" data-toggle="collapse" data-target=".navbar-collapse"
                                (click)="closeNavbar()">
                                <a routerLink="/team" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.team }}</a>
                            </li>

                            <li class="nav-item fw-bold  mb-2" data-toggle="collapse" data-target=".navbar-collapse"
                                (click)="closeNavbar()">
                                <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.about }}</a>
                            </li>

                            <!-- <li class="nav-item fw-bold" data-toggle="collapse" data-target=".navbar-collapse"
                                (click)="closeNavbar()">
                                <a routerLink="/blog" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link">{{ headerData?.blog
                                    }}</a>
                            </li> -->

                            <li class="nav-item fw-bold" data-toggle="collapse" data-target=".navbar-collapse"
                                (click)="closeNavbar()">
                                <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">{{ headerData?.contact }}</a>
                            </li>
                        </ul>

                        <!-- <div class="d-flex align-items-center px-3 order-2 mt-1" style="width: 54px;"> -->
                        <!-- <button type="button" class="btn btn-outline border-0 d-flex  custom-btn"
                                (click)="selectLanguage()" data-bs-toggle="tooltip" data-bs-placement="top"
                                data-bs-custom-class="custom-tooltip"
                                data-bs-title="This top tooltip is themed via CSS variables.">
                                <i class='bx bx-world fs-3'></i>
                            </button> -->
                        <div class="language-selector mt-3">
                            <select class="form-select bg-transparent border-0 fs-3 fw-bold mx-4"
                                [(ngModel)]="selectedLang.code" (change)="selectLanguage()">
                                <option *ngFor="let item of langList" [value]="item?.code">
                                    {{ item?.name }}
                                </option>
                            </select>
                        </div>
                        <!-- </div> -->
                    </div>

                    <!-- <div *ngIf="showSubMenu" class="sub-menu">
                        <div class="d-flex align-items-center justify-content-between py-3">
                            <button class="back-button" (click)="closeSubMenu()">
                                <i style="color: #080a3c;"
                                    [ngClass]="currentLang() === 'en' ? 'bx bx-chevron-left fs-1 ':'bx bx-chevron-right fs-1'"></i>
                            </button>
                            <button class="close-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <i class='bx bx-x fs-1' style="color: #080a3c;"></i>
                            </button>
                        </div>
                        <div *ngIf="currentSubMenu === 'service'">
                            <ul class="px-4">
                                <li class="nav-item fw-bold" *ngFor="let item of currentServiceDetails">
                                    <a (click)="openSubMenu(item.id, item.servicesDetails.services)" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }">{{ item.title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="currentSubMenu && currentSubMenu !== 'service'">
                            <ul class="px-4">
                                <li class="nav-item fw-bold" *ngFor="let subItem of currentServiceDetails">
                                    <a class="nav-link" [routerLink]="['/service-details', currentSubMenu, subItem.id]"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }" (click)="closeNavbar()">{{ subItem.title }}</a>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </nav>
    </div>
</div>