<div class="overflow mb-2 mb-lg-5  ">
    <div class="position-relative pt-4 pt-lg-5 ">
        <div class="container h-100 d-flex  align-items-center">

            <div class="  row justify-content-start align-items-start">
                <h4 class="header-md col-md-12 text-center">{{ projectDetails?.title }}</h4>

                <div class="col-lg-5 col-md-12">

                    <div class="wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img [src]="projectDetails?.image" alt="image" />
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="app-download-content">
                        <h4 class="header-lg">{{ projectDetails?.title }}</h4>
                        <p>
                            {{ projectDetails?.description }}
                        </p>
                        <span class="sub-title mt-3"
                            *ngIf="projectDetails?.buttons[0].url || projectDetails?.buttons[1].url">{{
                            projectDetails?.sub_title }}</span>
                        <div class="btn-box">
                            <div *ngFor="let item of projectDetails?.buttons">
                                <a class="apple-store-btn mb-3" [href]="item?.url" *ngIf="item?.url">
                                    <img [src]="item?.image_src" />
                                    {{ item?.text }}
                                    <span>{{ item?.store }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="header-btn text-center text-lg-end mb-3">
                        <button routerLink="/contact" class="default-btn rounded-pill"><i
                                [class]="projectData?.btn.btn_icon"></i><span></span> {{ projectData?.btn.btn_name
                            }}</button>
                    </div>

                    <div>
                        <div class="d-flex justify-content-center align-items-center">
                            <span
                                class=" border-bottom border-gray-300 border-soild border-2 col-4 col-lg-3    d-inline-block"></span>
                            <span class="mx-3">{{'GENERAL.AVILABLE_IN'|translate}}</span>
                            <span
                                class=" border-bottom border-gray-300 border-soild border-2 col-4 col-lg-7 d-inline-block"></span>

                        </div>
                        <div class="available-on">
                            <div class=" badges ">
                                <a   *ngFor="let item of projectDetails?.buttons" [href]="item.url" class=" badge ">
                                    <span class="mx-2" [inlineSVG]="item.image_src"></span>
                                    <span>{{item.store}}</span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>



<div class="container my-1 my-lg-5 pt-lg-5">
    <p-tabView class="py-5">
        <!-- Main Goals Tab -->
        <p-tabPanel [header]="'GENERAL.MAIN_GOALS'|translate" *ngIf="projectDetails?.detailed_description?.main_goals">
            <div class="d-flex flex-wrap">
                <div class="col-lg-9 col-md-12 order-md-2">
                    <div *ngFor="let goal of projectDetails?.detailed_description?.main_goals" class="mb-2">
                        <h5>{{ splitwords(goal, ':', 0) }}</h5>
                        <p>{{ splitwords(goal, ':', 1) }}</p>
                    </div>
                </div>
                <img class="col-lg-3 col-md-12 order-lg-2 order-md-1 p-5"
                    [src]="projectDetails?.detailed_description?.main_goal_img" alt="">
            </div>
        </p-tabPanel>

        <!-- App Components Tab -->
        <p-tabPanel [header]="'GENERAL.APP_COMPONENTS'|translate"
            *ngIf="projectDetails?.detailed_description?.components">
            <div class="d-flex flex-wrap">
                <div class="col-lg-9 col-md-12 order-md-2">
                    <div *ngFor="let component of projectDetails?.detailed_description?.components" class="mb-2">
                        <h5>{{ splitwords(component, ':', 0) }}</h5>
                        <p>{{ splitwords(component, ':', 1) }}</p>
                    </div>
                </div>
                <img class="col-lg-3 col-md-12 order-lg-2 order-md-1 p-5"
                    [src]="projectDetails?.detailed_description?.components_img" alt="">
            </div>
        </p-tabPanel>

        <!-- Techniques and Features Tab -->
        <p-tabPanel [header]="'GENERAL.TECHNIQES_FEATURES'|translate"
            *ngIf="projectDetails?.detailed_description?.educational_features">
            <div class="d-flex flex-wrap">
                <div class="col-lg-9 col-md-12 order-md-2">
                    <div *ngFor="let feature of projectDetails?.detailed_description?.educational_features"
                        class="mb-2">
                        <h5>{{ splitwords(feature, ':', 0) }}</h5>
                        <p>{{ splitwords(feature, ':', 1) }}</p>
                    </div>
                </div>
                <img class="col-lg-3 col-md-12 order-lg-2 order-md-1 p-5"
                    [src]="projectDetails?.detailed_description?.educational_features_img" alt="">
            </div>
        </p-tabPanel>

        <!-- Supervision and Quality Tab -->
        <p-tabPanel [header]="'GENERAL.SUPERVISION_AND_QUALITY'|translate"
            *ngIf="projectDetails?.detailed_description?.supervision_and_quality">
            <div class="d-flex flex-wrap">
                <div class="col-lg-9 col-md-12 order-md-2">
                    <div class="mb-2">
                        <h5>{{ splitwords(projectDetails?.detailed_description?.supervision_and_quality, ':', 0) }}</h5>
                        <p>{{ splitwords(projectDetails?.detailed_description?.supervision_and_quality, ':', 1) }}</p>
                    </div>
                </div>
                <img class="col-lg-3 col-md-12 order-lg-2 order-md-1 p-5"
                    [src]="projectDetails?.detailed_description?.suervision_and_quality_img" alt="">
            </div>
        </p-tabPanel>

        <!-- Technology Used Tab -->
        <p-tabPanel [header]="'GENERAL.TECHNOLOGY_USED'|translate"
            *ngIf="projectDetails?.detailed_description?.technology_used">
            <div class="d-flex flex-wrap">
                <div class="col-lg-9 col-md-12 order-md-2">
                    <div *ngFor="let tech of projectDetails?.detailed_description?.technology_used" class="mb-2">
                        <h5>{{ splitwords(tech, ':', 0) }}</h5>
                        <p>{{ splitwords(tech, ':', 1) }}</p>
                    </div>
                </div>
                <img class="col-lg-3 col-md-12 order-lg-2 order-md-1 p-5"
                    [src]="projectDetails?.detailed_description?.technology_used_img" alt="">
            </div>
        </p-tabPanel>

        <!-- User Interfaces Tab -->
        <p-tabPanel [header]="'GENERAL.USER_INTERFACES'|translate"
            *ngIf="projectDetails?.detailed_description?.user_interfaces">
            <div class="d-flex flex-wrap">
                <div class="col-lg-9 col-md-12 order-md-2">
                    <div *ngFor="let ui of projectDetails?.detailed_description?.user_interfaces" class="mb-2">
                        <h5>{{ splitwords(ui, ':', 0) }}</h5>
                        <p>{{ splitwords(ui, ':', 1) }}</p>
                    </div>
                </div>
                <img class="col-lg-3 col-md-12 order-lg-2 order-md-1 p-5"
                    [src]="projectDetails?.detailed_description?.user_interfaces_img" alt="">
            </div>
        </p-tabPanel>
    </p-tabView>
</div>



<div class="my-1 my-lg-5 pt-lg- 5" style="background-image: url('assets/img/orialCompany/bgService.png');  background-size: cover; ">
    <div class="container ">
        <h1 class=" pt-3 pt-lg-0">{{'GENERAL.GALLERY'|translate}}</h1>
        <div class="swiper swiper1">
            <div class="swiper-wrapper">
                <div class="swiper-slide p-3" *ngFor="let item of projectDetails?.gallary; odd as isOdd ; let i= index;">
                    <div class="d-flex flex-wrap galary-container-img" [ngClass]="{
                        'justify-content-between': i ==0,
                        'align-items-center': !isOdd && i!=0 ,
                        'align-items-start': isOdd && i!=0
                    }">
                        <div class="col-12" [ngClass]="isOdd ? 'order-lg-2' : 'order-lg-1'" *ngIf="i==0">
                            {{ item.title }}
                        </div>
                        <div class="col-12 my-3 " [ngClass]="isOdd ? 'order-lg-1' : 'order-lg-2'">
                            <img [src]="item.image" alt="img" >
                        </div>
                    </div>
                </div>

                <div class="swiper-pagination"></div>
            </div>
        </div>

    </div>
</div>






<section class="container my-5">
    <h1>{{'GENERAL.TECNOLGIES_USED'|translate}}</h1>
    <div class="d-flex justify-content-center align-items-center flex-wrap gap-3">
        <img *ngFor="let item of projectDetails?.detailed_description?.tecnolges_used_images" [src]="item" alt="img"
            class="grayscale-img">
    </div>

</section>



<section class="container my-5">
    <h1 class="my-3 mt-5">{{'GENERAL.FEEDBACK_CLINTS'|translate}}</h1>

    <div class=" swiper swiperFeedback ">
        <div class="swiper-wrapper">
            <div class="swiper-slide  " *ngFor="let item of portfolioData?.feedback">
                <!-- wrap slide content with transform element -->
              <div class="d-flex justify-content-center">
                <div class="row justify-content-center align-items-center flex-wrap rounded rounded-5 p-4 p-lg-3 feedback-container "
                style="background-color: #F6F4FD;">
                <div class="col-md-3  text-center">
                    <img class="rounded-circle " [src]="item.image" alt="">
                </div>
                <div class="col-md-9 row  gy-2">
                    <div class="col-12">
                        <img src="assets/img/orialCompany/Vector-1.png" alt="" class="quote-icon-start">
                    </div>
                    <p class="col-12 p-1 p-lg-5 text-center">{{ item.feedback }}</p>
                    <div class="col-12 text-end">
                        <img src="assets/img/orialCompany/Vector.png" alt="" class="quote-icon-end">
                    </div>
                    <div class="col-12 d-flex justify-content-start  justify-content-lg-between align-content-center ">
                        <div ></div>
                        <div>
                            <div class="fw-bold text-start ">{{item.customer_name}}</div>
                            <p >{{item.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>

</section>


<div class="container my-1 my-lg-3 ">
    <h1 class="my-5">{{'GENERAL.ANTHOR_PROJECTS'|translate}}</h1>
    <div class="swiper swiperPrpjects">
        <div class="swiper-wrapper">
            <div class="swiper-slide p-2" *ngFor="let item of projects">
                <!-- wrap slide content with transform element -->

                <div class="image-2 w-100 " [routerLink]="['/project', item.id]">
                    <img class="rounded-1 cursor-pointer w-100 object-fit-cover" [src]="item?.image"
                        sizes="(max-width: 396px) 100vw, 396px" [routerLink]="['/project', item.id]">
                    <!-- <div class="overlay"> -->
                    <div class="text-center mt-4">
                        <h6 class="text-dark fw-bold">{{ item?.title }}</h6>
                        <div class="fs-normal px-3 description" style="font-size: 12px; color: #4a6f8a;">{{
                            item?.description }}
                        </div>
                    </div>
                    <!-- </div> -->

                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>

</div>
<!--

<div class="swiper-container mt-3">
    <div class="swiper-wrapper" data-swiper-autoplay="25000">
        <div class="swiper-slide" *ngFor="let item of products">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="services-content it-service-content">
                        <div class="content left-content">
                            <h2>{{ item.name }}</h2>
                            <p>{{ item.description }}</p>
                        </div>
                        <div class="services-image wow animate__animated animate__fadeInRight content right-content col-lg-5 col-md-12"
                            data-wow-delay=".3s">
                            <div class="image d-flex justify-content-center">
                                <img [src]="item.image" alt="image" style="width: 450px;" class="mx-lg-auto px-lg-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        Add Pagination Dots Here
        <div class="swiper-pagination"></div>
    </div> -->


<!-- <div class="container" dir="ltr">
        <p-carousel #carousel [value]="products" [numVisible]="numVisible" [numScroll]="1"
            [responsiveOptions]="responsiveOptions" [(page)]="page" [showNavigators]="false">
            <ng-template pTemplate="header">
                <p>Header content</p>
            </ng-template>
            <ng-template let-product pTemplate="item">
                <div>
                    {{product.name}}
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <p>Footer content</p>
            </ng-template>
        </p-carousel>
    </div> -->

<!-- <div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + projectData?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ projectData?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                    <div class="header-btn">
                        <button routerLink="/contact" class="default-btn rounded-pill"><i
                                [class]="projectData?.btn.btn_icon"></i><span></span> {{ projectData?.btn.btn_name
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">

                <div class="wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <img [src]="projectDetails?.image" alt="image" />
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <h4>{{ projectDetails?.title }}</h4>
                    <p>
                        {{ projectDetails?.description }}
                    </p>
                    <span class="sub-title mt-3"
                        *ngIf="projectDetails?.buttons[0].url || projectDetails?.buttons[1].url">{{
                        projectDetails?.sub_title }}</span>
                    <div class="btn-box">
                        <div *ngFor="let item of projectDetails?.buttons">
                            <a class="apple-store-btn mb-3" [href]="item?.url" *ngIf="item?.url">
                                <img [src]="item?.image_src" />
                                {{ item?.text }}
                                <span>{{ item?.store }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="projectDetails?-details-content mt-5">
            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.main_goals">
                <h3 translate="GENERAL.MAIN_GOALS"></h3>
                <p *ngFor="let goal of projectDetails?.detailed_description?.main_goals">
                    {{ goal }}
                </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.components">
                <h4 translate="GENERAL.APP_COMPONENTS"></h4>
                <p *ngFor="let component of projectDetails?.detailed_description.components">
                    {{ component }}
                </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description.educational_features">
                <h4 translate="GENERAL.TECHNIQES_FEATURES"></h4>
                <p *ngFor="let feature of projectDetails?.detailed_description?.educational_features">
                    {{ feature }}
                </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.supervision_and_quality">
                <h4 translate="GENERAL.SUPERVISION_AND_QUALITY"></h4>
                <p> {{ projectDetails?.detailed_description?.supervision_and_quality }} </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.technology_used">
                <h4 translate="GENERAL.TECHNOLOGY_USED"></h4>
                <p *ngFor="let tech of projectDetails?.detailed_description?.technology_used">
                    {{ tech }}
                </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.user_interfaces">
                <h4 translate="GENERAL.USER_INTERFACES"></h4>
                <p *ngFor="let ui of projectDetails?.detailed_description?.user_interfaces">
                    {{ ui }}
                </p>
            </div>

            <p *ngIf="projectDetails?.detailed_description?.conclusion">{{
                projectDetails?.detailed_description?.conclusion }}</p>


        </div>
    </div>
</div> -->



<!-- <div class="services-area ptb-100 bg-f4f6fc">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/home-saas/feature5.png" alt="image" />
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="fun-facts-inner-content">
                        <h2>Build Beautiful Interface Into Your Application</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut
                            aliquip.
                        </p>
                        <div class="app-download-content">
                            <span class="sub-title">Download App</span>
                            <div class="btn-box mt-0">
                                <a href="#" class="apple-store-btn"
                                    ><img
                                        src="assets/img/applestore.png"
                                        alt="image"
                                    />Download on the<span>Apple Store</span></a
                                >
                                <a href="#" class="play-store-btn"
                                    ><img
                                        src="assets/img/playstore.png"
                                        alt="image"
                                    />Get it on<span>Google Play</span></a
                                >
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9">
        <img src="assets/img/shape/shape9.png" alt="image" />
    </div>
</div> -->

<!-- <section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be the best support to you as possible</h2>
            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello&#64;jexsa.com" name="EMAIL" required>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>
            <div class="shape14"><img src="assets/img/shape/shape13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/shape14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/shape15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/shape16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/shape17.png" alt="image"></div>
        </div>
    </div>
</section> -->

<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></button>
        </div>
    </div>
</section>
