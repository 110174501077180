import {
    ChangeDetectorRef,
    Component,
    OnInit,
    AfterViewInit,
} from "@angular/core";
import { Subscription, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";
import { TranslationService } from "src/assets/i18n";
import { Swiper } from "swiper";
// import 'swiper/swiper-bundle.css'; // Ensure Swiper CSS is imported

// Install Swiper modules
// Swiper.use([Autoplay, Navigation, Scrollbar]);

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit, AfterViewInit {
    private unsubscribe: Subscription[] = [];
    contact_section: any;
    serviceData: any;
    homeData: any;
    private swiper: Swiper;
    private intervalId: any;
    isRtl: boolean;
    portfolioData$: any;
    constructor(
        private services: ServicesService,
        private cdf: ChangeDetectorRef,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.isRtl =
            this.translationService.getSelectedLanguage() === "ar"
                ? true
                : false;

        this.services.getThreeRandomPortfolioItems();
        this.services.portfolioItems
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((portfolioData$) => {
                (this.portfolioData$ = portfolioData$),
                    console.log(portfolioData$);
                this.cdf.detectChanges();
            });
        this.services.getServices();
        this.services.getGeneralData();
        this.services.contact_section
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((contact_section) => {
                (this.contact_section = contact_section),
                    this.cdf.detectChanges();
            });
        this.services.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                (this.homeData = data?.home), this.cdf.detectChanges();
            });
        this.services.servicesData
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((serviceData) => {
                (this.serviceData = serviceData), this.cdf.detectChanges();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    ngAfterViewInit(): void {
        this.swiper = new Swiper(".swiper-container", {
            direction: "horizontal",
            loop: true,
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: true,
            },
            // autoplay: {
            //     delay: 500,
            // },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        this.startAutoplay();
    }

    startAutoplay(): void {
        this.intervalId = setInterval(() => {
            if (this.swiper) {
                this.swiper.slideNext();
            }
        }, 25000);
    }

    currentLang() {
        return this.translationService.getSelectedLanguage();
    }
}
