<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + blogData?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ blogData?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of blogData?.blogs">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a [routerLink]="['/blog-details', item.id]"><img [src]="item?.image" alt="image"
                                class="rounded-1" /></a>
                        <div class="date">
                            <i class="bx bx-calendar"></i> {{ item?.date }}
                        </div>
                    </div>
                    <div class="post-content">
                        <h3 class="fs-6 truncate">
                            <a [routerLink]="['/blog-details', item.id]">{{ item?.title }}</a>
                        </h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img [src]="item?.profileImage" alt="image" />
                                <h6>{{ item?.name }}</h6>
                            </div>
                            <div class="details-btn">
                                <a [routerLink]="['/blog-details', item.id]"><i class="{{ item?.arrow_icon }}"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a href="#" class="prev page-numbers"><i class="bx bxs-arrow-to-left"></i></a>
                    <a href="#" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a href="#" class="page-numbers">3</a>
                    <a href="#" class="page-numbers">4</a>
                    <a href="#" class="next page-numbers"><i class="bx bxs-arrow-to-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <a routerLink="/contact" class="default-btn rounded-pill"><i [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></a>
        </div>
    </div>
</section>