<!-- <div id="carouselExampleDark" class="carousel carousel-dark slide">
    <div class="carousel-inner">
        <div class="carousel-item" data-bs-interval="10000" *ngFor="let i of teamData.images; let index = index"
            [ngClass]="{active: index === 1}">

            <img [src]="i" class="d-block w-100">
             <div class="carousel-caption d-none d-md-block">
                <h2>hbjbvnbv</h2>
                <p> n bnb nbv nb nbn</p>
            </div> 
        </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
        <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
        <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div> -->

<div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
            class="active visually-hidden" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"
            class="visually-hidden"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"
            class="visually-hidden"></button>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let i of teamData?.images; let index = index"
            [ngClass]="{active: index == 0}">
            <img [src]="i" class="d-block w-100">
            <div class="carousel-caption d-none d-md-block">
                <h1 class="mt-5">{{ teamData?.title }}</h1>
                <p class="fs-4 text-white-50 mt-5">{{ teamData?.description }}</p>
            </div>
        </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next">
        <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>


<section class="feedback-area pt-100 pb-70" style="background-color: #F3F3F3;">
    <div class="container">
        <div class="row gap-2">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="image border-0 rounded-8 h-100 object-fit-cover">
                    <img [src]="teamData?.founder.image" class="rounded-8 h-100 object-fit-cover w-100" />
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 mt-5">
                <div class="client-info mb-3">
                    <span>{{ teamData?.founder.jobTitle }}</span>
                    <h3 style="font-weight: 600; font-size: 1.3rem;">{{ teamData?.founder.name }}</h3>
                </div>
                <p class="fw-medium" style="white-space: pre-line;">
                    {{ teamData?.founder.description }}
                </p>
            </div>
        </div>
    </div>
</section>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <!-- <h2 class="fs-2" style="font-weight: 600;"> {{ teamData?.team.title }}</h2>
        <p class="fw-medium w-75">
            {{ teamData?.team.description }}
        </p> -->
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 my-5">
                <h3> {{ teamData?.team.title }}</h3>
                <p style="white-space: pre-line;">
                    {{ teamData?.team.description }}
                </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let item of  teamData?.team.team">
                <div class="single-team-box  h-100">
                    <div class="border-0 rounded-8 h-75 object-fit-cover">
                        <img [src]="item.image" alt="image" class="rounded-8 h-100 object-fit-cover" />
                    </div>
                    <div class="content mx-3 mt-2" style="text-align: start;">
                        <h3 class="mb-1" style="font-weight: 600; font-size: 1.1rem;">{{ item.name }}</h3>
                        <span style="font-weight: 500; color: #4a6f8a;">{{ item.jobTitle }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>