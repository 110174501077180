<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + blogData?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ blogData?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/img/blog-image/img1.jpg" alt="image" class="rounded-1" />
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class="bx bx-time"></i>
                                    <a href="#">September 31, 2023</a>
                                </li>
                                <li>
                                    <i class="bx bx-user"></i>
                                    <a href="#">Steven Smith</a>
                                </li>
                            </ul>
                        </div>
                        <h3>
                            50 world-changing people -- We lost in the 2010s
                        </h3>
                        <p>
                            Quuntur magni dolores eos qui ratione voluptatem
                            sequi nesciunt. Neque porro quia non numquam eius
                            modi tempora incidunt ut labore et dolore magnam
                            dolor sit amet, consectetur adipisicing.
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in sed quia non numquam eius modi
                            tempora incidunt ut labore et dolore magnam aliquam
                            quaerat voluptatem.
                        </p>
                        <blockquote class="wp-block-quote">
                            <p>
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                                when looking at its layout.
                            </p>
                            <cite>Tom Cruise</cite>
                        </blockquote>
                        <p>
                            Quuntur magni dolores eos qui ratione voluptatem
                            sequi nesciunt. Neque porro quia non numquam eius
                            modi tempora incidunt ut labore et dolore magnam
                            dolor sit amet, consectetur adipisicing.
                        </p>
                        <!-- <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img
                                        src="assets/img/blog-image/img8.jpg"
                                        alt="image"
                                    />
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img
                                        src="assets/img/blog-image/img7.jpg"
                                        alt="image"
                                    />
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img
                                        src="assets/img/blog-image/img9.jpg"
                                        alt="image"
                                    />
                                </figure>
                            </li>
                        </ul> -->
                        <h3>Four major elements that we offer:</h3>
                        <ul class="features-list">
                            <li>
                                <i class="bx bx-check-double"></i> Scientific
                                Skills For getting a better result
                            </li>
                            <li>
                                <i class="bx bx-check-double"></i> Communication
                                Skills to getting in touch
                            </li>
                            <li>
                                <i class="bx bx-check-double"></i> A Career
                                Overview opportunity Available
                            </li>
                            <li>
                                <i class="bx bx-check-double"></i> A good Work
                                Environment For work
                            </li>
                        </ul>
                        <h3>Setting the mood with incense</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in sed quia non numquam eius modi
                            tempora incidunt ut labore et dolore magnam aliquam
                            quaerat voluptatem.
                        </p>
                        <h3>The Rise Of Smarketing And Why You Need It</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud.
                        </p>
                    </div>
                    <div class="article-footer">
                        <div class="article-tags">
                            <a>Fashion</a>
                            <a>Smart</a>
                            <a>Marketing</a>
                            <a>Games</a>
                            <a>Travel</a>
                        </div>
                    </div>
                    <!-- <div class="jexsa-post-navigation">
                        <div class="prev-link-wrapper">
                            <div class="info-prev-link-wrapper">
                                <a href="#">
                                    <span class="image-prev">
                                        <img
                                            src="assets/img/blog-image/img2.jpg"
                                            alt="image"
                                        />
                                        <span class="post-nav-title">Prev</span>
                                    </span>
                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title"
                                            >Don't buy a tech gift until you
                                            read these rules</span
                                        >
                                        <span class="meta-wrapper">
                                            <span class="date-post"
                                                >January 21, 2023</span
                                            >
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="next-link-wrapper">
                            <div class="info-next-link-wrapper">
                                <a href="#">
                                    <span class="next-link-info-wrapper">
                                        <span class="next-title"
                                            >The golden rule of buying a phone
                                            as a gift</span
                                        >
                                        <span class="meta-wrapper">
                                            <span class="date-post"
                                                >January 21, 2023</span
                                            >
                                        </span>
                                    </span>
                                    <span class="image-next">
                                        <img
                                            src="assets/img/blog-image/img3.jpg"
                                            alt="image"
                                        />
                                        <span class="post-nav-title">Next</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>
                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img
                                                src="assets/img/author-image/img1.jpg"
                                                class="avatar"
                                                alt="image"
                                            />
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>
                                        <div class="comment-metadata">
                                            <a href="#">
                                                April 24, 2023 at 10:59 am
                                            </a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>
                                            Lorem Ipsum has been the industry’s
                                            standard dummy text ever since the
                                            1500s, when an unknown printer took
                                            a galley of type and scrambled it to
                                            make a type specimen.
                                        </p>
                                    </div>
                                    <div class="reply">
                                        <a href="#" class="comment-reply-link"
                                            >Reply</a
                                        >
                                    </div>
                                </article>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div
                                                    class="comment-author vcard"
                                                >
                                                    <img
                                                        src="assets/img/author-image/img2.jpg"
                                                        class="avatar"
                                                        alt="image"
                                                    />
                                                    <b class="fn"
                                                        >Steven Smith</b
                                                    >
                                                    <span class="says"
                                                        >says:</span
                                                    >
                                                </div>
                                                <div class="comment-metadata">
                                                    <a href="#">
                                                        April 24, 2023 at 10:59
                                                        am
                                                    </a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>
                                                    Lorem Ipsum has been the
                                                    industry’s standard dummy
                                                    text ever since the 1500s,
                                                    when an unknown printer took
                                                    a galley of type and
                                                    scrambled it to make a type
                                                    specimen.
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <a
                                                    href="#"
                                                    class="comment-reply-link"
                                                    >Reply</a
                                                >
                                            </div>
                                        </article>
                                    </li>
                                    <ol class="children">
                                        <li class="comment">
                                            <article class="comment-body">
                                                <footer class="comment-meta">
                                                    <div
                                                        class="comment-author vcard"
                                                    >
                                                        <img
                                                            src="assets/img/author-image/img3.jpg"
                                                            class="avatar"
                                                            alt="image"
                                                        />
                                                        <b class="fn"
                                                            >Sarah Taylor</b
                                                        >
                                                        <span class="says"
                                                            >says:</span
                                                        >
                                                    </div>
                                                    <div
                                                        class="comment-metadata"
                                                    >
                                                        <a href="#">
                                                            April 24, 2023 at
                                                            10:59 am
                                                        </a>
                                                    </div>
                                                </footer>
                                                <div class="comment-content">
                                                    <p>
                                                        Lorem Ipsum has been the
                                                        industry’s standard
                                                        dummy text ever since
                                                        the 1500s, when an
                                                        unknown printer took a
                                                        galley of type and
                                                        scrambled it to make a
                                                        type specimen.
                                                    </p>
                                                </div>
                                                <div class="reply">
                                                    <a
                                                        href="#"
                                                        class="comment-reply-link"
                                                        >Reply</a
                                                    >
                                                </div>
                                            </article>
                                        </li>
                                    </ol>
                                </ol>
                            </li>
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img
                                                src="assets/img/author-image/img4.jpg"
                                                class="avatar"
                                                alt="image"
                                            />
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>
                                        <div class="comment-metadata">
                                            <a href="#">
                                                April 24, 2023 at 10:59 am
                                            </a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>
                                            Lorem Ipsum has been the industry’s
                                            standard dummy text ever since the
                                            1500s, when an unknown printer took
                                            a galley of type and scrambled it to
                                            make a type specimen.
                                        </p>
                                    </div>
                                    <div class="reply">
                                        <a href="#" class="comment-reply-link"
                                            >Reply</a
                                        >
                                    </div>
                                </article>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div
                                                    class="comment-author vcard"
                                                >
                                                    <img
                                                        src="assets/img/author-image/img1.jpg"
                                                        class="avatar"
                                                        alt="image"
                                                    />
                                                    <b class="fn"
                                                        >James Anderson</b
                                                    >
                                                    <span class="says"
                                                        >says:</span
                                                    >
                                                </div>
                                                <div class="comment-metadata">
                                                    <a href="#">
                                                        April 24, 2023 at 10:59
                                                        am
                                                    </a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>
                                                    Lorem Ipsum has been the
                                                    industry’s standard dummy
                                                    text ever since the 1500s,
                                                    when an unknown printer took
                                                    a galley of type and
                                                    scrambled it to make a type
                                                    specimen.
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <a
                                                    href="#"
                                                    class="comment-reply-link"
                                                    >Reply</a
                                                >
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>
                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes"
                                        >Your email address will not be
                                        published.</span
                                    >
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea
                                        name="comment"
                                        id="comment"
                                        cols="45"
                                        rows="5"
                                        maxlength="65525"
                                        required="required"
                                    ></textarea>
                                </p>
                                <p class="comment-form-author">
                                    <label
                                        >Name
                                        <span class="required">*</span></label
                                    >
                                    <input
                                        type="text"
                                        id="author"
                                        name="author"
                                        required="required"
                                    />
                                </p>
                                <p class="comment-form-email">
                                    <label
                                        >Email
                                        <span class="required">*</span></label
                                    >
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        required="required"
                                    />
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" name="url" />
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input
                                        type="checkbox"
                                        value="yes"
                                        name="wp-comment-cookies-consent"
                                        id="wp-comment-cookies-consent"
                                    />
                                    <label for="wp-comment-cookies-consent"
                                        >Save my name, email, and website in
                                        this browser for the next time I
                                        comment.</label
                                    >
                                </p>
                                <p class="form-submit">
                                    <input
                                        type="submit"
                                        name="submit"
                                        id="submit"
                                        class="submit"
                                        value="Post A Comment"
                                    />
                                </p>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text"
                                    >Search for:</span
                                >
                                <input
                                    type="search"
                                    class="search-field"
                                    placeholder="Search..."
                                />
                            </label>
                            <button type="submit">
                                <i class="bx bx-search-alt"></i>
                            </button>
                        </form>
                    </section>
                    <section class="widget widget_jexsa_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"
                                ><span
                                    class="fullimage cover bg1"
                                    role="img"
                                ></span
                            ></a>
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall">
                                    <a routerLink="/blog-details"
                                        >How To Resubmit Rejected Item Into
                                        ThemeForest?</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"
                                ><span
                                    class="fullimage cover bg2"
                                    role="img"
                                ></span
                            ></a>
                            <div class="info">
                                <time datetime="2023-06-30">June 21, 2023</time>
                                <h4 class="title usmall">
                                    <a routerLink="/blog-details"
                                        >How To Create A Responsive Popup
                                        Gallery?</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"
                                ><span
                                    class="fullimage cover bg3"
                                    role="img"
                                ></span
                            ></a>
                            <div class="info">
                                <time datetime="2023-06-30">June 30, 2023</time>
                                <h4 class="title usmall">
                                    <a routerLink="/blog-details"
                                        >How To The Active Menu Based On URL In
                                        Next.JS?</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a href="#">Business</a></li>
                            <li><a href="#">Privacy</a></li>
                            <li><a href="#">Technology</a></li>
                            <li><a href="#">Tips</a></li>
                            <li><a href="#">Uncategorized</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>
                        <ul>
                            <li><a href="#">May 2023</a></li>
                            <li><a href="#">April 2023</a></li>
                            <li><a href="#">June 2023</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>
                        <ul>
                            <li><a href="#">Log in</a></li>
                            <li>
                                <a href="#"
                                    >Entries
                                    <abbr title="Really Simple Syndication"
                                        >RSS</abbr
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    >Comments
                                    <abbr title="Really Simple Syndication"
                                        >RSS</abbr
                                    ></a
                                >
                            </li>
                            <li><a href="#">WordPress.org</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud">
                            <a href="#"
                                >IT <span class="tag-link-count"> (3)</span></a
                            >
                            <a href="#"
                                >Orial<span class="tag-link-count">
                                    (3)</span
                                ></a
                            >
                            <a href="#"
                                >Games
                                <span class="tag-link-count"> (2)</span></a
                            >
                            <a href="#"
                                >Fashion
                                <span class="tag-link-count"> (2)</span></a
                            >
                            <a href="#"
                                >Travel
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a href="#"
                                >Smart
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a href="#"
                                >Marketing
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a href="#"
                                >Tips
                                <span class="tag-link-count"> (2)</span></a
                            >
                        </div>
                    </section>
                </aside>
            </div> -->
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <a routerLink="/contact" class="default-btn rounded-pill"><i [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></a>
        </div>
    </div>
</section>