import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";

@Component({
    selector: "app-services-one",
    templateUrl: "./services-one.component.html",
    styleUrls: ["./services-one.component.scss"],
})
export class ServicesOneComponent implements OnInit {
    serviceData: any;
    contact_section: any;

    _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        private services: ServicesService,
        private cdf: ChangeDetectorRef
    ) {
        this.services.getServices();
    }

    ngOnInit() {
        this.services.servicesData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((serviceData) => {
                (this.serviceData = serviceData), this.cdf.detectChanges();
            });
        this.services.contact_section
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((contact_section) => {
                (this.contact_section = contact_section),
                    this.cdf.detectChanges();
            });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
