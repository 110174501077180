<section
    class="error-area background"
    style="background-image: url('assets/img/bg.jpg')"
>
    <div class="d-table content">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content box" style="height: 97vh">
                    <img src="assets/img/Orial_logo.png" alt="error" />
                    <h3>Undergoing Maintenance</h3>
                    <p>
                        We're currently undergoing maintenance to enhance your
                        experience. Stay tuned for exciting updates!
                        <br />
                        Our website is temporarily down for maintenance. We
                        apologize for any inconvenience and look forward to
                        sharing our latest innovations with you soon...
                    </p>
                    <div class="d-flex my-3" style="margin: 0 90px">
                        Contact Us
                    </div>
                    <div
                        class="d-flex justify-content-between"
                        style="margin: 0 90px"
                    >
                        <div class="d-flex">
                            <div>Email:</div>
                            <a class="mx-2">{{ email }}</a>
                        </div>
                        <div class="d-flex">
                            <div>Phone:</div>
                            <div class="mx-2">+201099026602</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
