import { ChangeDetectorRef, Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { get } from "jquery";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";

@Component({
    selector: "app-protofolio",
    templateUrl: "./protofolio.component.html",
    styleUrl: "./protofolio.component.scss",
})
export class ProtofolioComponent {
    contact_section: any;

    private unsubscribe: Subject<void> = new Subject<void>();
    private subscriptions: Subscription[] = [];

    serviceData: any[] = []; // Assuming this is your array of service data
    portfolioData: any; // Assuming this is your selected portfolio data
    protfolioData$: any[] = []; // Assuming this is your array of portfolio items

    activeItemId: any = "01";
    activeSubItemIds: { [key: string]: any } = {};

    mainService: any;
    subService: any;

    constructor(
        private services: ServicesService,
        private cdf: ChangeDetectorRef
    ) {}

    getPortfolioData(mainServiceId: string, subServiceUniqueId?: string): void {
        // Reset protfolioData$
        this.protfolioData$ = [];

        // Call service method to fetch portfolio data
        this.services.getPortfolioBySubService(
            mainServiceId,
            subServiceUniqueId
        );

        // Subscribe to portfolioForMainService
        this.subscriptions.push(
            this.services.portfolioForMainService
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.protfolioData$ = data;
                    this.cdf.detectChanges();
                })
        );

        // Subscribe to portfolioForSubService if subServiceUniqueId is provided
        if (subServiceUniqueId !== undefined && subServiceUniqueId !== null) {
            this.subscriptions.push(
                this.services.portfolioForSubService
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((data) => {
                        this.protfolioData$ = data;
                        this.cdf.detectChanges();
                    })
            );
        }
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.services.getServices();
        this.services.getGeneralData();

        this.subscriptions.push(
            this.services.data
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.portfolioData = data?.portfolio;
                    this.serviceData = data?.data?.mainServices;
                    this.cdf.detectChanges();
                    if (this.serviceData && this.serviceData.length > 0) {
                        this.activeItemId = this.serviceData[0].unique_id; // Set the first service as active by default
                        this.mainService = this.serviceData[0];
                        // this.activeSubItemIds[this.activeItemId] =
                        //     this.mainService.servicesDetails.services[0]?.unique_id; // Set the first sub-service as active by default
                        // this.subService =
                        //     this.mainService.servicesDetails.services[0];
                        this.getPortfolioData(this.activeItemId);
                    }
                })
        );
        this.subscriptions.push(
            this.services.contact_section
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((contact_section) => {
                    (this.contact_section = contact_section),
                        this.cdf.detectChanges();
                })
        );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    setActiveItem(id: string): void {
        this.activeItemId = id;
        this.mainService = this.getActiveMain();
        this.subService = "";
        this.protfolioData$ = [];
        this.getPortfolioData(id);
        this.activeSubItemIds[id] = -1; // Reset the active subitem ID
    }

    setActiveSubItem(mainId: string, subId: string, event: Event): void {
        event.stopPropagation();
        this.activeSubItemIds[mainId] = subId;
        this.subService = this.getActiveSub();
        this.protfolioData$ = [];
        this.getPortfolioData(mainId, subId);
    }

    getActiveMain(): string {
        if (this.activeItemId === -1) {
            return "";
        }
        const mainService = this.serviceData?.find(
            (service) => service.unique_id === this.activeItemId
        );
        return mainService ? mainService : "";
    }

    getActiveSub(): string {
        if (
            this.activeItemId === -1 ||
            this.activeSubItemIds[this.activeItemId] === -1
        ) {
            return "";
        }
        const mainService = this.serviceData?.find(
            (service) => service.unique_id === this.activeItemId
        );
        const subService = mainService.servicesDetails.services.find(
            (service) =>
                service.unique_id === this.activeSubItemIds[this.activeItemId]
        );
        return subService ? subService : "";
    }
}
