<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms and Conditions</h2>
        </div>
    </div>
</div>

<div class="container ptb-100">
    <!-- <h2>Terms and Conditions</h2> -->
    <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam,
        veritatis odit? Eos corrupti facilis voluptas modi. Explicabo nulla,
        quia sequi tempore quam iure tempora praesentium in esse odit dolor
        minus!
    </p>
</div>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>
                Qualify your leads & recognize the value of word your customer
                will love you
            </p>
            <a routerLink="/contact" class="default-btn rounded-pill"><i class="bx bxs-hot"></i> Contact
                Us<span></span></a>
        </div>
    </div>
    <div class="shape10">
        <img src="assets/img/shape/shape10.png" alt="image" />
    </div>
    <div class="shape11">
        <img src="assets/img/shape/shape7.png" alt="image" />
    </div>
    <div class="shape12">
        <img src="assets/img/shape/shape11.png" alt="image" />
    </div>
    <div class="shape13">
        <img src="assets/img/shape/shape12.png" alt="image" />
    </div>
</section>