<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + portfolioData?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ portfolioData?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                    <div class="header-btn">
                        <button routerLink="/contact" class="default-btn rounded-pill"><i
                                [class]="portfolioData?.btn?.btn_icon"></i><span></span> {{ portfolioData?.btn?.btn_name
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section class="team-area pt-100 pb-70">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light mb-3">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-0 rounded-1" style="background-color: #F3F3F3;">
                    <li class="nav-item" *ngFor="let item of serviceData; let mainIndex = index"
                        (click)="setActiveItem(mainIndex)" [ngClass]="{'active': activeItemIndex === mainIndex}"
                        data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a class="nav-link p-3" style="cursor: pointer;">
                            {{ item.title }}
                        </a>
                        <ul class="dropdown-menu" *ngIf="item.servicesDetails.services.length">
                            <li class="nav-item"
                                *ngFor="let subItem of item.servicesDetails.services; let subIndex = index"
                                (click)="setActiveSubItem(mainIndex, subIndex, $event)"
                                [ngClass]="{'active': activeSubItemIndexes[mainIndex] === subIndex}"
                                data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a class="nav-link" style="cursor: pointer;">{{ subItem.title }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>

        <ul class="breadcrumb fw-medium fs-6 mx-3 mt-1">
            <li *ngIf="activeItemIndex !== -1"><a style="cursor: pointer;">
                    <p class="fw-medium fs-6">{{ serviceData[activeItemIndex]?.title }}</p>
                </a></li>
            <li
                *ngIf="activeItemIndex !== -1 && activeSubItemIndexes[activeItemIndex] !== undefined && activeSubItemIndexes[activeItemIndex] !== -1">
                <a style="cursor: pointer;">
                    <p class="fw-medium fs-6">{{
                        serviceData[activeItemIndex]?.servicesDetails.services[activeSubItemIndexes[activeItemIndex]]?.title
                        }}</p>
                </a>
            </li>
        </ul>

        <div class="row mt-5">
            <div class="col-lg-4 col-md-6 col-sm-6 mt-5">
                <h3>Our Innovative
                    Projects</h3>
                <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis iste esse, tempore
                    consectetur modi quisquam odio. Tempore assumenda repellat alias id iure soluta aliquid quod quae
                    illum adipisci, officia eveniet.
                </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of getActiveData()">
                <div class="single-team-box">
                    <div class="image border-0" routerLink="/project">
                        <img [src]="item" alt="image" class="rounded-1" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="team-area py-5">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light mb-3 d-lg-flex justify-content-lg-center w-100">
            <ul class="navbar-nav m-0 rounded-5 px-3" style="background-color: #F3F3F3;">
                <li class="nav-item p-2 d-flex my-1" *ngFor="let item of serviceData; let i = index"
                    (click)="setActiveItem(item.unique_id)" [ngClass]="{'active': activeItemId === item.unique_id}">
                    <a class="nav-link m-0 p-0" style="cursor: pointer;">{{ item.title }}</a>
                    <span class="mx-3 border-dash" *ngIf="i < serviceData.length - 1"></span>
                    <ul class="dropdown-menu" *ngIf="item.servicesDetails?.services.length">
                        <li class="nav-item" *ngFor="let subItem of item.servicesDetails.services"
                            (click)="setActiveSubItem(item.unique_id, subItem.unique_id, $event)"
                            [ngClass]="{'active': activeSubItemIds[item.unique_id] === subItem.unique_id}">
                            <a class="nav-link" style="cursor: pointer;">{{ subItem.title }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>

        <div class="row mt-lg-5">
            <div [ngClass]="protfolioData$.length ==0? 'col-12 mt-3' : 'col-lg-4 col-md-6 col-sm-6 mt-3'">
                <ul class="breadcrumb fw-medium fs-6 my-3">
                    <li *ngIf="activeItemId !== '-1'"><a style="cursor: pointer;">
                            <p class="fw-normal fs-6">{{ mainService?.title }}</p>
                        </a></li>
                    <li
                        *ngIf="activeItemId !== '-1' && activeSubItemIds[activeItemId] !== undefined && activeSubItemIds[activeItemId] !== '-1'">
                        <a style="cursor: pointer;">
                            <p class="fw-normal fs-6">{{ subService?.title}}</p>
                        </a>
                    </li>
                </ul>
                <div *ngIf="!subService">
                    <h3>{{ mainService?.title }}</h3>
                    <p style="white-space: pre-line;">{{ mainService?.long_description }}</p>
                </div>
                <div
                    *ngIf="activeItemId !== '-1' && activeSubItemIds[activeItemId] !== undefined && activeSubItemIds[activeItemId] !== '-1'">
                    <h3>{{ subService?.title }}</h3>
                    <p style="white-space: pre-line;">{{ subService?.description }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of protfolioData$">
                <div class="image-2 w-100 mb-5" [routerLink]="['/project', item.id]">
                    <img class="rounded-1 cursor-pointer w-100 object-fit-cover" [src]="item?.image"
                        sizes="(max-width: 396px) 100vw, 396px" [routerLink]="['/project', item.id]">
                    <!-- <div class="overlay"> -->
                    <div class="text-center mt-4">
                        <h6 class="text-dark fw-bold">{{ item?.title }}</h6>
                        <div class="fs-normal px-3 description" style="font-size: 12px; color: #4a6f8a;">{{
                            item?.description }}
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area pt-100">
    <div class="container">
        <div class="section-title">
            <h2 class="fs-3" style="font-weight: 600;"> {{ portfolioData?.subTitle }} {{ portfolioData?.company_name
                }}</h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme" style="height: 300px;">
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[0]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[0]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[0]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[0]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[1]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[1]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[1]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[1]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[2]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[2]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[2]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[2]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[3]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[3]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[3]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[3]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[4]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[4]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[4]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[4]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[5]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[5]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[5]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[5]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[6]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[6]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[6]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[6]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[7]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[7]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[7]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[7]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[8]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[8]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[8]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[8]?.description }}</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item my-0">
                <img [src]="portfolioData?.feedback[9]?.image" alt="image" />
                <div class="feedback-desc">
                    <p>
                        {{ portfolioData?.feedback[9]?.feedback }}
                    </p>
                    <div class="client-info mt-3">
                        <h3>{{ portfolioData?.feedback[9]?.customer_name }}</h3>
                        <span>{{ portfolioData?.feedback[9]?.description }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn?.btn_icon"></i>
                {{
                contact_section?.btn?.btn_name }}<span></span></button>
        </div>
    </div>
</section>
