<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + servicesDataById?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ servicesDataById?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 my-4">
                <h3>{{ servicesDataById?.subTitle }}</h3>
                <p>
                    {{ servicesDataById?.description }}
                </p>
            </div>
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-lg-6 col-md-6 " *ngFor="let item of servicesDataById?.services; let index = index">
                        <div class="features-box-one rounded-8" style="cursor: pointer;"
                            [routerLink]="['/services/service-details', serviceId, servicesDataById?.services[index].id]">
                            <i class="bg-13c4a1" [inlineSVG]=" item?.icon"></i>
                            <h3>{{ item?.title }}</h3>
                            <p class="description">
                                {{ item?.description }}
                            </p>
                            <div [routerLink]="['/services/service-details', serviceId, servicesDataById?.services[index].id]"
                                class="read-more">{{
                                item?.btn.btn_name }} <i [class]="item?.btn.btn_icon"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="container">
    <div class="contact-form">
        <h3 class="mt-3 w-100 d-flex justify-content-center" translate="GENERAL.TITLE_FORM"></h3>
        <form [formGroup]="contactForm" class="form  d-flex justify-content-center" (ngSubmit)="submit()">
            <div class="row w-50">
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control" required
                            [placeholder]="'GENERAL.NAME' | translate" formControlName="Name" />
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control" required
                            [placeholder]="'GENERAL.EMAIL' | translate" formControlName="Email" />
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" required class="form-control"
                            [placeholder]="'GENERAL.PHONE' | translate" formControlName="PhoneNumber" />
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                            [placeholder]="'GENERAL.SUBJECT' | translate" formControlName="Subject" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required
                            [placeholder]="'GENERAL.MESSAGE' | translate" formControlName="Message"></textarea>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 mb-3">
                    <label for="fileInput" class="custom-file-upload">
                        <input type="file" id="fileInput" style="display:none;" (change)="onFileSelected($event)">
                        <img src="https://res.cloudinary.com/dtutqsucw/image/upload/v1438960670/file-upload.png">
                        <span class="mx-3" translate="GENERAL.UPLOAD"></span>
                    </label>
                </div>
                <div class="col-lg-6 col-md-6 d-flex justify-content-end mb-3">
                    <button type="submit" class="default-btn rounded-pill" translate="GENERAL.BTN_FORM">
                        <i class="bx bxs-paper-plane" style="color: #fff"></i>
                        <span>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div> -->

<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></button>
        </div>
    </div>
</section>