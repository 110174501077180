<div class="overflow">
    <div class="position-relative py-lg-5">

        <div class="container h-100 d-flex  align-items-center">
            <div class=" text-center text-lg-end row justify-content-start align-items-start">
                <div class="col-lg-6 col-md-12 p-md-3  order-2 order-lg-1">
                    <div class="wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                        <img [src]="servicesDetailsById?.image" alt="image" class="responsive-image rounded-8" />
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 p-lg-0 mb-md-2 order-1 order-lg-2">
                    <ul class="breadcrumb  mx-2 mt-1 mb-3">
                        <li><a [routerLink]="['/service', id]">
                                <p class="fw-normal ">{{ id }}</p>
                            </a></li>
                        <li>
                            <a class="cursor-none">
                                <p class="fw-normal " style=" color: #613f93;">{{ serviceId }}</p>
                            </a>
                        </li>
                    </ul>
                    <h1 class=" w-70 mb-xl-4 mb-2 fw-bolder bold-700 ">{{ servicesDetailsById?.header }}</h1>
                    <p class="fw-normal fs-6">
                        {{servicesDetailsById?.main_description}}
                    </p>
                    <div class="header-btn mb-2">
                        <button routerLink="/contact" class="default-btn rounded-pill"><i
                                [class]="servicesDetailsById?.btn.btn_icon"></i><span></span> {{
                            servicesDetailsById?.btn.btn_name
                            }}</button>
                    </div>

                </div>
            </div>

        </div>

    </div>

</div>

<div class=" stats-section ">
    <div class="container d-flex">
        <div class="col-3 stat-box" *ngFor="let item of servicesDetailsById?.numbers">
            <h2>{{item.number}}</h2>
            <p>{{item.title}}</p>
        </div>
    </div>
</div>



<div class="service-details ptb-100 container" *ngIf="sizePageLg">
    <div class=" row justify-content-center align-items-start" *ngFor="let item of servicesData?.details.sections ; odd as isOdd ;let i= index"
    [ngClass]="i!== servicesData?.details.sections.length-1 ? ' mb-5 pb-5' : ''">
        <div class="col-lg-6 col-md-12 " [ngClass]="{'order-lg-2': isOdd, 'order-lg-1': !isOdd,'order-md-2':true}">
            <h3>{{ item?.title }}</h3>
            <p class="fw-normal fs-6">{{ item?.description }}</p>

            <div>
                <ul class="checkmark-list">
                    <li class="mb-1" *ngFor="let list of item?.points"><span [inlineSVG]="'assets/img/icons/icon-park-solid_check-one.svg'"
                            class="d-inline-block mx-2"></span> {{ list }}</li>
                </ul>
            </div>
        </div>
        <div class="col-lg-6 col-md-12" [ngClass]="{'order-lg-1': isOdd, 'order-lg-2': !isOdd ,'order-md-1':true}">
            <img [src]="item?.image" alt="image"
                style="max-width: 500px;" />
        </div>
    </div>
</div>



<div class="container section-md mt-4">
    <div class="service-details  " >
        <div class="swiper swiperSections">
            <div class="swiper-wrapper">
                <div class="swiper-slide "  *ngFor="let item of servicesData?.details.sections ; odd as isOdd">
                    <div class=" row justify-content-center align-items-start  my-lg-2">
                        <div class="col-lg-6 col-md-12" [ngClass]="{'order-lg-1': isOdd, 'order-lg-2': !isOdd ,'order-md-1':true}">
                            <h3>{{ item?.title }}</h3>
                            <img [src]="item?.image" alt="image"
                            style="max-width: auto;" />
                        </div>
                        <div class="col-lg-6 col-md-12 " [ngClass]="{'order-lg-2': isOdd, 'order-lg-1': !isOdd,'order-md-2':true}">
                            <p class="fw-normal fs-6 mt-2">{{ item?.description }}</p>

                            <div>
                                <ul class="checkmark-list">
                                    <li class="mb-1" *ngFor="let list of item?.points"><span [inlineSVG]="'assets/img/icons/icon-park-solid_check-one.svg'"
                                            class="d-inline-block mx-2"></span> {{ list }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-pagination"></div>
            </div>
        </div>

    </div>

</div>





<div class="overflow py-md-1" style="background-image: url('assets/img/orialCompany/bgService.png');">

    <div class="container d-flex justify-content-center align-items-center ">
        <div class="col-lg-6 col-md-12 p-4 py-lg-5  ">
            <div class="contact-form rounded-form">
                <h3 class="mt-3">{{ servicesDetailsById?.title_form }}</h3>
                <p >{{ servicesDetailsById?.description_form }}</p>
                <form [formGroup]="contactForm" class="form" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control rounded-5 mb-2" required
                                    [placeholder]="'GENERAL.NAME' | translate" formControlName="Name" />
                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'required',
                                        message: 'GENERAL.NAME_REQUIRED' | translate,
                                        control: contactForm.controls['Name']
                                    }"></ng-container>
                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'minlength',
                                        message: 'GENERAL.NAME_MIN' | translate,
                                        control: contactForm.controls['Name']
                                    }"></ng-container>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control rounded-5 mb-2" required
                                    [placeholder]="'GENERAL.EMAIL' | translate" formControlName="Email" />
                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'required',
                                        message: 'GENERAL.EMAIL_REQUIRED' | translate,
                                        control: contactForm.controls['Email']
                                    }"></ng-container>
                                <ng-container *ngIf="!contactForm.controls['Email'].errors?.minlength"
                                    [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'email',
                                        message: 'GENERAL.EMAIL_INVALID' | translate,
                                        control: contactForm.controls['Email']
                                    }"></ng-container>
                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'minlength',
                                        message: 'GENERAL.EMAIL_LENGTH' | translate,
                                        control: contactForm.controls['Email']
                                    }"></ng-container>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="number" name="phone_number" id="phone_number" required
                                    class="form-control rounded-5 mb-2" [placeholder]="'GENERAL.PHONE' | translate"
                                    formControlName="PhoneNumber" />

                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'required',
                                        message: 'GENERAL.PHONE_REQUIRED' | translate,
                                        control: contactForm.controls['PhoneNumber']
                                    }"></ng-container>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject"
                                    class="form-control rounded-5 mb-2" required
                                    [placeholder]="'GENERAL.SUBJECT' | translate" formControlName="Subject" />
                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                    validation: 'required',
                                    message: 'GENERAL.SUBJECT_REQUIRED' | translate,
                                    control: contactForm.controls['Subject']
                                }"></ng-container>
                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                    validation: 'minlength',
                                    message: 'GENERAL.SUBJECT_MIN' | translate,
                                    control: contactForm.controls['Subject']
                                }"></ng-container>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control rounded-5 mb-2" id="message" cols="30"
                                    rows="6" required [placeholder]="'GENERAL.MESSAGE' | translate"
                                    formControlName="Message"></textarea>
                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'required',
                                        message: 'GENERAL.MSG_REQUIRED' | translate,
                                        control: contactForm.controls['Message']
                                    }"></ng-container>
                                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'minlength',
                                        message: 'GENERAL.MSG_MIN' | translate,
                                        control: contactForm.controls['Message']
                                    }"></ng-container>
                            </div>
                        </div>
                        <div
                            class="col-lg-6 col-md-6 col-6 d-flex justify-content-lg-start justify-content-center mb-3 align-items-center">
                            <button type="submit" class="default-btn rounded-pill" [disabled]="contactForm.invalid">
                                <i class="bx bxs-paper-plane" style="color: #fff"></i>
                                <ng-container *ngIf="!isLoading">
                                    {{ servicesDetailsById?.btn_form }}
                                </ng-container>
                                <ng-container *ngIf="isLoading">
                                    <div translate="GENERAL.LOADING" class="d-inline-block mx-2"></div>
                                    <div class="spinner-border spinner-border-sm text-light px-1"></div>
                                </ng-container>
                            </button>
                        </div>
                        <ng-container>
                            <div class="col-lg-6 col-md-6 col-6 mb-3 d-flex justify-content-lg-end">
                                <label for="fileInput" class="custom-file-upload rounded-pill">
                                    <input type="file" id="fileInput" style="display:none;"
                                        (change)="onFileSelected($event)">
                                    <img src="https://res.cloudinary.com/dtutqsucw/image/upload/v1438960670/file-upload.png"
                                        class="mx-1 imageUpload">
                                    <span class="mx-2" translate="GENERAL.UPLOAD"></span>
                                </label>
                            </div>
                        </ng-container>

                    </div>
                </form>
            </div>
        </div>
    </div>

</div>


<div class="container my-lg-3 ">
    <h1 class="my-3 my-lg-5">{{'GENERAL.ANTHOR_PROJECTS'|translate}}</h1>
    <div class="swiper swiperProjects">
        <div class="swiper-wrapper">
            <div class="swiper-slide p-2 " *ngFor="let item of protfolioForSubService">

                <div class="image-2 w-100 " [routerLink]="['/project', item.id]">
                    <img class="rounded-1 cursor-pointer w-100 object-fit-cover" [src]="item?.image"
                        sizes="(max-width: 396px) 100vw, 396px" [routerLink]="['/project', item.id]">
                    <!-- <div class="overlay"> -->
                    <div class="text-center mt-4">
                        <h6 class="text-dark fw-bold">{{ item?.title }}</h6>
                        <div class="fs-normal px-3 description" style="font-size: 12px; color: #4a6f8a;">{{
                            item?.description }}
                        </div>
                    </div>
                    <!-- </div> -->

                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>

</div>




<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></button>
        </div>
    </div>
</section>




<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
        <div class="fv-plugins-message-container">
            <span role="alert" class="text-danger">{{ message }}</span>
        </div>
    </ng-container>
</ng-template>
