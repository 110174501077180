<!-- <app-preloader class="content"></app-preloader> -->
<app-navbar *ngIf="
        !(
            location == '/error' ||
            location == '/log-in' ||
            location == '/sign-up'
        )
    "></app-navbar>
<router-outlet class="content"></router-outlet>
<app-footer *ngIf="
        !(
            location == '/error' ||
            location == '/log-in' ||
            location == '/sign-up'
        )
    " class="content"></app-footer>

<!-- <app-error></app-error> -->