<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + contactData?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ contactData?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="contact-area ptb-100">
    <div class="container">
        <!-- contact-inner -->
        <div class=" rounded-8">
            <div class="row">
                <div class="col-lg-5 col-md-12 bg-white rounded-7" style=" box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
                    <div class="p-3">
                        <div class="contact-info-content">
                            <h3 class="mt-3" style="font-weight: 600;">{{ contactData?.title }}</h3>
                            <div class="contact-features-list">
                                <ul class="footer-contact-info mt-5">
                                    <li class="p-0 mb-4 d-flex">
                                        <span class="bx bx-phone fs-5"></span>
                                        <h6 class="mx-1">{{ footerData?.phone.phone }}:</h6>
                                        <a class="mx-1" href="tel:+201099026602">(+20)
                                            {{ footerData?.phone.info }}</a>
                                    </li>
                                    <li class="p-0 mb-4 d-flex">
                                        <span class="bx bxl-whatsapp fs-4"></span>
                                        <h6 class="mx-1">{{ footerData?.phone.title_2 }}:</h6>
                                        <a class="mx-1" href="https://api.whatsapp.com/send/?phone=201097693576"
                                            target="_blank">(+20) {{ footerData?.phone.whatsapp }}</a>
                                    </li>
                                    <li class="p-0 mb-4 d-flex">
                                        <span class="bx bx-envelope fs-5"></span>
                                        <h6 class="mx-1">{{ footerData?.email.title }}:</h6>
                                        <a class="mx-1" href="mailto:Info&#64;Orial.Co" target="_blank">{{
                                            footerData?.email.info }}</a>
                                    </li>

                                    <li class="p-0 mb-4 d-flex">
                                        <span class="bx bx-map fs-5"></span>
                                        <h6 class="mx-1">{{ footerData?.address.address }}:</h6>
                                        <a class="mx-1"
                                            href="https://maps.app.goo.gl/qQeEUqnSq6ozjs7cA?g_st=com.google.maps.preview.copy"
                                            target="_blank">{{ footerData?.address.info }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info ">
                        <div class="contact-info-content">
                            <div class="fw-bold fs-5" translate="GENERAL.FOLLOW_US"></div>
                            <ul class="social">
                                <li>
                                    <a href="https://twitter.com/orialcompany" target="_blank">
                                        <i>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                                <path
                                                    d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z">
                                                </path>
                                            </svg>
                                        </i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/orialllc" target="_blank"><i
                                            class="bx bxl-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/oriallab/" target="_blank"><i
                                            class="bx bxl-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/orialcompany?igshid=MzRlODBiNWFlZA%3D%3D"
                                        target="_blank"><i class="bx bxl-instagram fs-4"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="contact-form">
                        <h3 class="mt-3" style="font-weight: 600;">{{ contactData?.form_title }}</h3>
                        <form [formGroup]="contactForm" class="form" (ngSubmit)="submit()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <select style="padding-top: 0.7rem; padding-bottom: 0.7rem;"
                                        class="form-select my-3 bg-transparent border rounded-5" id="selectType"
                                        formControlName="TypeId" (change)="onChangeType($event)">
                                        <option disabled selected value="0" translate="GENERAL.REQUIEST_TYPE"></option>
                                        <option value="1" translate="GENERAL.REQUEST_SERVICE"></option>
                                        <option value="2" translate="GENERAL.COMPLAINT"></option>
                                        <option value="4" translate="GENERAL.INQUERY"></option>
                                    </select>
                                    <div class="fv-plugins-message-container text-danger mb-3" *ngIf="
                                    contactForm.get('TypeId')?.hasError('invalidTypeId') &&
                                    contactForm.get('TypeId')?.touched
                                    " translate="GENERAL.TYPE_REQUEST_REQUIRED">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <ng-container [ngSwitch]="contactForm.get('TypeId').value">
                                        <div *ngSwitchCase="'1'">
                                            <select class="form-select my-3 bg-transparent border rounded-5"
                                                style="padding-top: 0.7rem; padding-bottom: 0.7rem;" id="serviceSelect"
                                                formControlName="ServiceId">
                                                <option disabled selected value="null" translate="GENERAL.SERICE_TYPE">
                                                </option>
                                                <option *ngFor="let item of serviceData" [value]="item.unique_id">{{
                                                    item.title
                                                    }}</option>
                                            </select>
                                            <div class="fv-plugins-message-container text-danger mb-2" *ngIf="
                                            contactForm.get('ServiceId')?.hasError('required') &&
                                            contactForm.get('ServiceId')?.touched
                                            " translate="GENERAL.TYPE_SERVICE_REQUIRED">
                                            </div>
                                        </div>
                                        <!-- Add other cases if needed -->
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control rounded-5 mb-2"
                                            required [placeholder]="'GENERAL.NAME' | translate"
                                            formControlName="Name" />
                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'required',
                                                message: 'GENERAL.NAME_REQUIRED' | translate,
                                                control: contactForm.controls['Name']
                                            }"></ng-container>
                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'minlength',
                                                message: 'GENERAL.NAME_MIN' | translate,
                                                control: contactForm.controls['Name']
                                            }"></ng-container>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control rounded-5 mb-2"
                                            required [placeholder]="'GENERAL.EMAIL' | translate"
                                            formControlName="Email" />
                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'required',
                                                message: 'GENERAL.EMAIL_REQUIRED' | translate,
                                                control: contactForm.controls['Email']
                                            }"></ng-container>
                                        <ng-container *ngIf="!contactForm.controls['Email'].errors?.minlength"
                                            [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'email',
                                                message: 'GENERAL.EMAIL_INVALID' | translate,
                                                control: contactForm.controls['Email']
                                            }"></ng-container>
                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'minlength',
                                                message: 'GENERAL.EMAIL_LENGTH' | translate,
                                                control: contactForm.controls['Email']
                                            }"></ng-container>

                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="number" name="phone_number" id="phone_number" required
                                            class="form-control rounded-5 mb-2"
                                            [placeholder]="'GENERAL.PHONE' | translate" formControlName="PhoneNumber" />

                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'required',
                                                message: 'GENERAL.PHONE_REQUIRED' | translate,
                                                control: contactForm.controls['PhoneNumber']
                                            }"></ng-container>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject"
                                            class="form-control rounded-5 mb-2" required
                                            [placeholder]="'GENERAL.SUBJECT' | translate" formControlName="Subject" />
                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'required',
                                            message: 'GENERAL.SUBJECT_REQUIRED' | translate,
                                            control: contactForm.controls['Subject']
                                        }"></ng-container>
                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'minlength',
                                            message: 'GENERAL.SUBJECT_MIN' | translate,
                                            control: contactForm.controls['Subject']
                                        }"></ng-container>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control rounded-5 mb-2" id="message"
                                            cols="30" rows="6" required [placeholder]="'GENERAL.MESSAGE' | translate"
                                            formControlName="Message"></textarea>
                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'required',
                                                message: 'GENERAL.MSG_REQUIRED' | translate,
                                                control: contactForm.controls['Message']
                                            }"></ng-container>
                                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                                validation: 'minlength',
                                                message: 'GENERAL.MSG_MIN' | translate,
                                                control: contactForm.controls['Message']
                                            }"></ng-container>
                                    </div>
                                </div>
                                <ng-container>
                                    <div class="col-lg-6 col-md-6 col-12 mb-3">
                                        <label for="fileInput"
                                            [ngClass]="{ 'custom-file-upload': true, 'disabled': contactForm.get('TypeId').value != 1 }"
                                            class="rounded-pill">
                                            <input type="file" id="fileInput" style="display:none;"
                                                (change)="onFileSelected($event)"
                                                [disabled]="contactForm.get('TypeId').value != 1">
                                            <img src="https://res.cloudinary.com/dtutqsucw/image/upload/v1438960670/file-upload.png"
                                                class="mx-1">
                                            <span class="mx-2" translate="GENERAL.UPLOAD"></span>
                                        </label>
                                    </div>
                                </ng-container>
                                <div
                                    class="col-lg-6 col-md-6 col-12 d-flex justify-content-lg-end justify-content-center mb-3 align-items-center">
                                    <button type="submit" class="default-btn rounded-pill"
                                        [disabled]="contactForm.invalid">
                                        <i class="bx bxs-paper-plane" style="color: #fff"></i>
                                        <ng-container *ngIf="!isLoading">
                                            {{ contactData?.btn_send }}
                                        </ng-container>
                                        <ng-container *ngIf="isLoading">
                                            <div translate="GENERAL.LOADING" class="d-inline-block mx-2"></div>
                                            <div class="spinner-border spinner-border-sm text-light px-1"></div>
                                        </ng-container>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            <!-- <div class="contact-info">
                <div class="contact-info-content">
                    <ul class="social">
                        <li>
                            <a href="https://twitter.com/orialcompany" target="_blank"><i
                                    class="bx bxl-twitter"></i></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/orialllc" target="_blank"><i
                                    class="bx bxl-facebook"></i></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/oriallab/" target="_blank"><i
                                    class="bx bxl-linkedin"></i></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/orialcompany?igshid=MzRlODBiNWFlZA%3D%3D"
                                target="_blank"><i class="bx bxl-instagram"></i></a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
        <div class="mt-5 rounded-8">
            <p>
                <iframe class="rounded-7"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.054906938011!2d31.4370002887886!3d30.006579755767277!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458399e02aa2973%3A0x71f67485f4b62acc!2sOrial%20Corporation!5e0!3m2!1sen!2seg!4v1692097990447!5m2!1sen!2seg"
                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </p>
        </div>
    </div>
</section>



<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
        <div class="fv-plugins-message-container">
            <span role="alert" class="text-danger">{{ message }}</span>
        </div>
    </ng-container>
</ng-template>