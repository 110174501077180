import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    contactData: any;
    footerData: any;

    file: any;
    fileUrl: string;
    contactForm: FormGroup;

    serviceId: any;

    serviceData: any;

    isLoading = false;
    _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        private services: ServicesService,
        private cdf: ChangeDetectorRef,
        private http: HttpClient,
        private fb: FormBuilder,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.initForm();
        this.services.getGeneralData();
        this.services.servicesData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((serviceData) => {
                this.serviceData = serviceData?.mainServices;
                this.cdf.detectChanges();
            });
        this.services.getServices();
        this.services.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.contactData = data?.contact_us;
                this.footerData = data?.footer;
                this.cdf.detectChanges();
            });
    }

    initForm() {
        // this.contactForm = this.fb.group({
        //     Name: [
        //         "",
        //         Validators.compose([
        //             Validators.required,
        //             Validators.minLength(3),
        //         ]),
        //     ],
        //     Email: [
        //         "",
        //         Validators.compose([
        //             Validators.required,
        //             Validators.email,
        //             Validators.minLength(3),
        //         ]),
        //     ],
        //     PhoneNumber: ["", Validators.required],
        //     Message: [
        //         "",
        //         Validators.compose([
        //             Validators.required,
        //             Validators.minLength(3),
        //         ]),
        //     ],
        //     Subject: [
        //         "",
        //         Validators.compose([
        //             Validators.required,
        //             Validators.minLength(3),
        //         ]),
        //     ],
        //     TypeId: [0, Validators.required],
        //     ServiceId: [""],
        // });

        this.contactForm = this.fb.group({
            Name: ["", [Validators.required, Validators.minLength(3)]],
            Email: [
                "",
                [
                    Validators.required,
                    Validators.email,
                    Validators.minLength(3),
                ],
            ],
            PhoneNumber: ["", Validators.required],
            Message: ["", [Validators.required, Validators.minLength(3)]],
            Subject: ["", [Validators.required, Validators.minLength(3)]],
            TypeId: [0, [Validators.required, this.validateTypeId]],
            ServiceId: [null],
        });
    }

    validateTypeId(control: AbstractControl) {
        const value = control.value;
        return value === 0 ? { invalidTypeId: true } : null;
    }

    onChangeType(event: any) {
        const typeId = event.target.value;
        const serviceIdControl = this.contactForm.get("ServiceId");
        if (typeId === "1") {
            // Ensure typeId is compared as a string if event.target.value is a string
            serviceIdControl.setValidators([Validators.required]);
        } else {
            serviceIdControl.setValidators(null);
        }
        serviceIdControl.updateValueAndValidity();
    }

    onFileSelected(event: any) {
        this.file = event.target.files[0];
        if (this.file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.fileUrl = e.target.result;
                this.cdf.detectChanges();
            };
            reader.readAsDataURL(this.file);
        }
    }

    submit() {
        this.isLoading = true;
        let formBody = this.contactForm.value;
        let formData = new FormData();

        if (this.file) {
            formData.append("FileURL", this.file);
        }

        for (let outerKey in formBody) {
            if (formBody[outerKey] !== null) {
                formData.append(outerKey, formBody[outerKey]);
            }
        }

        if (this.contactForm.valid) {
            this.http
                .post(
                    "https://website.orial-eg.com/api/v1/ContactUs/Create",
                    formData
                )
                .subscribe({
                    next: (res: any) => {
                        Swal.fire({
                            icon: "success",
                            text: this.translate.instant("GENERAL.MSG_SUCCESS"),
                            showCloseButton: true,
                            focusCancel: false,
                            showConfirmButton: false,
                            buttonsStyling: false, // Disable default styling
                            customClass: {
                                popup: "custom-popup-class",
                                title: "custom-title-class",
                                htmlContainer: "custom-content-class",
                                footer: "custom-footer-class",
                                confirmButton: "custom-confirm-button-class",
                            },
                        });
                        this.resetForm();
                        this.isLoading = false; // Set isLoading to false on success
                    },
                    error: (err: any) => {
                        this.isLoading = false; // Set isLoading to false on error
                        Swal.fire({
                            icon: "error",
                            text: this.translate.instant("GENERAL.MSG_ERROR"),
                            showCloseButton: true,
                            focusCancel: false,
                            showConfirmButton: false,
                            buttonsStyling: false, // Disable default styling
                            customClass: {
                                popup: "custom-popup-class",
                                title: "custom-title-class",
                                htmlContainer: "custom-content-class",
                                footer: "custom-footer-class",
                                confirmButton: "custom-confirm-button-class",
                            },
                        });
                    },
                    complete: () => {
                        this.isLoading = false; // Ensure isLoading is set to false on completion
                    },
                });
        } else {
            this.isLoading = false; // Set isLoading to false if form is invalid
            Swal.fire({
                icon: "error",
                text: this.translate.instant("GENERAL.MSG_ERROR"),
                showCloseButton: true,
                focusCancel: false,
                showConfirmButton: false,
                buttonsStyling: false, // Disable default styling
                customClass: {
                    popup: "custom-popup-class",
                    title: "custom-title-class",
                    htmlContainer: "custom-content-class",
                    footer: "custom-footer-class",
                    confirmButton: "custom-confirm-button-class",
                },
            });
        }
    }

    resetForm() {
        this.contactForm.reset();
        this.contactForm.patchValue({ TypeId: 0 }); // Set the default value for TypeId
        this.fileUrl = "";
        this.isLoading = false;
        this.cdf.detectChanges();
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
