import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {
  provideFluentDesignSystem,
  fluentButton,
  fluentTextField
} from '@fluentui/web-components';
import { Swiper, SwiperOptions } from 'swiper/types';

provideFluentDesignSystem()
  .register(
        fluentButton(),
        fluentTextField());
        
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


  
// const swiperParams: SwiperOptions = {
//   slidesPerView: 3,
//   spaceBetween: 50,
// };

// const swiper = new Swiper('.swiper', swiperParams);

