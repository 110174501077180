<footer class="footer-area pt-4 " style="background-color: #F0F2F5;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="single-footer-widget mb-3">
                    <div class="mb-3">
                        <a class="symbol" routerLinkActive="active menu-here" routerLink="/">
                            <img alt="Logo" src="assets/img/orialCompany/footer.png" class="app-sidebar-logo-default" />
                        </a>
                    </div>
                    <p class="fw-normal">
                        {{ footerData?.description }}
                    </p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-6">
                <div class="single-footer-widget">
                    <h5 class="mb-4" style="font-weight: 600;">{{ headerData?.services }}</h5>
                    <ul class="support-list">
                        <li *ngFor="let item of serviceData"><a [routerLink]="['/service', item.id]">{{ item.title
                                }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-6">
                <div class="single-footer-widget">
                    <h5 class="mb-4" style="font-weight: 600;">{{ headerData?.company }}</h5>
                    <ul class="support-list">
                        <li><a routerLink="/about">{{ headerData?.about }}</a></li>
                        <li><a routerLink="/portfolio">{{ headerData?.portfolio }}</a></li>
                        <li><a routerLink="/team"> {{ headerData?.team }}</a></li>
                        <!-- <li><a routerLink="/blog">{{ headerData?.blog }}</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-6">
                <div class="single-footer-widget">
                    <h5 class="mb-4" style="font-weight: 600;">{{ footerData?.contact_us }}</h5>

                    <ul class="support-list">
                        <li>
                            <a class="fw-medium d-flex align-items-center" href="mailto:Info&#64;Orial.Co"
                                target="_blank">
                                <i class="bx bx-envelope fs-4"></i>
                                <span class="mx-2"> {{ footerData?.email.info }} </span>
                            </a>
                        </li>
                        <li><a routerLink="/portfolio"><a class="fw-medium d-flex align-items-center"
                                    href="tel:+201099026602" target="_blank">
                                    <i class="bx bx-phone fs-4"></i>
                                    <span class="mx-1"> (+20) {{ footerData?.phone.info }} </span>
                                </a></a></li>
                        <li><a class="fw-medium d-flex align-items-center"
                                href="https://api.whatsapp.com/send/?phone=201097693576" target="_blank">
                                <i class="bx bxl-whatsapp fs-3"></i>
                                <span class=""> (+20) {{ footerData?.phone.whatsapp }} </span>
                            </a></li>
                        <li>
                            <!-- <a class="fw-medium d-flex align-items-start" style="white-space: pre-line;"
                                href="https://www.google.com/maps?ll=30.006506,31.441804&z=15&t=m&hl=en&gl=EG&mapclient=embed&cid=8211879089241729740"
                                target="_blank">
                                <i class="bx bx-map fs-4"></i>
                                <span class="mx-1 whitespace-pre-line whitespace-nowrap"> {{ footerData?.address.info }}
                                </span>
                            </a> -->
                            <a class="fw-medium d-flex align-items-start"
                                href="https://maps.app.goo.gl/qQeEUqnSq6ozjs7cA?g_st=com.google.maps.preview.copy"
                                target="_blank">
                                <i class="bx bx-map fs-4"></i>
                                <span class="description mx-1">
                                    {{ footerData?.address.info }}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-6">
                <div class="single-footer-widget">
                    <h5 class="mb-4" style="font-weight: 600;"> {{ footerData?.quick_links.title }}</h5>
                    <ul class="support-list">
                        <li>
                            <a routerLink="/privacy" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">{{ footerData?.quick_links.privacy }}</a>
                        </li>
                        <li>
                            <a routerLink="/"></a>
                            <a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">{{ footerData?.quick_links.terms }}</a>
                        </li>
                        <li>
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">{{ footerData?.quick_links.contact }}</a>
                        </li>
                    </ul>

                    <div class="d-lg-block d-none">
                        <h6 style="font-weight: 600;" class="mt-2">{{ footerData?.follow_us }}</h6>
                        <div class="contact-info mt-0" style="text-align: start;">
                            <div class="contact-info-content">
                                <ul class="social mt-0">
                                    <li>
                                        <a href="https://twitter.com/orialcompany" target="_blank">
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                    fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z">
                                                    </path>
                                                </svg>
                                            </i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/orialllc" target="_blank"><i
                                                class="bx bxl-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/oriallab/" target="_blank"><i
                                                class="bx bxl-linkedin"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/orialcompany?igshid=MzRlODBiNWFlZA%3D%3D"
                                            target="_blank"><i class="bx bxl-instagram fs-4"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column w-100 align-items-center single-footer-widget d-lg-none d-block">
                <h6 style="font-weight: 600;" class="mt-2">{{ footerData?.follow_us }}</h6>
                <div class="contact-info mt-0" style="text-align: start;">
                    <div class="contact-info-content">
                        <ul class="social mt-0">
                            <li>
                                <a href="https://twitter.com/orialcompany" target="_blank">
                                    <i>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                            fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                            <path
                                                d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z">
                                            </path>
                                        </svg>
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/orialllc" target="_blank"><i
                                        class="bx bxl-facebook"></i></a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/oriallab/" target="_blank"><i
                                        class="bx bxl-linkedin"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/orialcompany?igshid=MzRlODBiNWFlZA%3D%3D"
                                    target="_blank"><i class="bx bxl-instagram fs-4"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area mt-0 pt-2 d-flex align-items-center w-100">

            <div class="flex-center w-100">
                <span>{{ footerData?.copy_right }}</span> {{ companyName }} {{ currentYear }} <span>{{ footerData?.right
                    }}</span>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline border-0 d-flex  custom-btn" (click)="selectLanguage()">
                    <span translate="GENERAL.LANG" class="  FS-6 mx-2"></span>

                    <i class='bx bx-world fs-5'></i>
                </button>
            </div>
        </div>
    </div>
</footer>

<!-- <div class="contact-icon">
    <a class="call-button p-0 go-2" href="tel:+201099026602" target="_blank">
        <span class="button-overlay">
            <div class="icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" style="fill:#fff">
                    <path
                        d="m20.487 17.14-4.065-3.696a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a.997.997 0 0 0-.085-1.39z">
                    </path>
                </svg>
            </div>
        </span>
    </a>

    <a class="whatsapp-button p-0 go" href="https://api.whatsapp.com/send/?phone=201097693576" target="_blank">
        <span class="button-overlay">
            <div class="icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 90 90" width="32" height="32">
                    <path
                        d="M90 43.841c0 24.213-19.779 43.841-44.182 43.841a44.256 44.256 0 0 1-21.357-5.455L0 90l7.975-23.522a43.38 43.38 0 0 1-6.34-22.637C1.635 19.628 21.416 0 45.818 0 70.223 0 90 19.628 90 43.841zM45.818 6.982c-20.484 0-37.146 16.535-37.146 36.859 0 8.065 2.629 15.534 7.076 21.61L11.107 79.14l14.275-4.537A37.122 37.122 0 0 0 45.819 80.7c20.481 0 37.146-16.533 37.146-36.857S66.301 6.982 45.818 6.982zm22.311 46.956c-.273-.447-.994-.717-2.076-1.254-1.084-.537-6.41-3.138-7.4-3.495-.993-.358-1.717-.538-2.438.537-.721 1.076-2.797 3.495-3.43 4.212-.632.719-1.263.809-2.347.271-1.082-.537-4.571-1.673-8.708-5.333-3.219-2.848-5.393-6.364-6.025-7.441-.631-1.075-.066-1.656.475-2.191.488-.482 1.084-1.255 1.625-1.882.543-.628.723-1.075 1.082-1.793.363-.717.182-1.344-.09-1.883-.27-.537-2.438-5.825-3.34-7.977-.902-2.15-1.803-1.792-2.436-1.792-.631 0-1.354-.09-2.076-.09s-1.896.269-2.889 1.344c-.992 1.076-3.789 3.676-3.789 8.963 0 5.288 3.879 10.397 4.422 11.113.541.716 7.49 11.92 18.5 16.223C58.2 65.771 58.2 64.336 60.186 64.156c1.984-.179 6.406-2.599 7.312-5.107.9-2.512.9-4.663.631-5.111z">
                    </path>
                </svg>
            </div>
        </span>
    </a>
</div> -->

<!-- <div class="go-top"><i class="bx bx-chevron-up"></i></div> -->