import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  private unsubscribe: Subscription[] = [];
  contact_section: any;
  teamData: any;

  constructor(private services: ServicesService, private cdf: ChangeDetectorRef,) { }

  ngOnInit() {
    this.services.getServices();
    this.services.getGeneralData();
    this.services.data.pipe(takeUntil(this.unsubscribe)).subscribe((data) => { 
      this.teamData = data?.team, 
      this.cdf.detectChanges() 
    });
    this.services.contact_section.pipe(takeUntil(this.unsubscribe)).subscribe((contact_section) => { 
      this.contact_section = contact_section, 
      this.cdf.detectChanges() 
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  
}
