import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  private unsubscribe: Subscription[] = [];
  aboutData: any;
  contact_section: any;
  
  constructor(private services: ServicesService, private cdf: ChangeDetectorRef,) { }

  ngOnInit() {
    this.services.getServices();
    this.services.getGeneralData();
    this.services.data.pipe(takeUntil(this.unsubscribe)).subscribe((data) => { 
      this.aboutData = data.about, 
      this.cdf.detectChanges() 
    });
    this.services.contact_section.pipe(takeUntil(this.unsubscribe)).subscribe((contact_section) => { 
      this.contact_section = contact_section, 
      this.cdf.detectChanges() 
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
