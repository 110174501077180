import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Subscription, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";
import { TranslationService } from "src/assets/i18n";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    companyName: string = "Orial llc";
    currentYear: number;
    private unsubscribe: Subscription[] = [];
    footerData: any;
    serviceData: any;
    headerData: any;

    constructor(
        private services: ServicesService,
        private translationService: TranslationService,
        private cdf: ChangeDetectorRef
    ) {
        this.currentYear = new Date().getFullYear();
    }

    ngOnInit() {
        this.services.getGeneralData();
        this.services.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                (this.footerData = data?.footer), this.cdf.detectChanges();
            });
        this.services.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                (this.serviceData = data?.data.mainServices),
                    this.cdf.detectChanges();
            });
        this.services.data
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                (this.headerData = data?.header), this.cdf.detectChanges();
            });
    }
    // currentLang() {
    //     return this.translationService.getSelectedLanguage();
    // }
    // getButtonPosition1() {
    //     if (this.currentLang() === "ar") {
    //         return { right: "170px" };
    //     } else {
    //         return { left: "170px" };
    //     }
    // }
    // getButtonPosition2() {
    //     if (this.currentLang() === "ar") {
    //         return { right: "95px" };
    //     } else {
    //         return { left: "95px" };
    //     }
    // }

    currentLang() {
        return this.translationService.getSelectedLanguage();
    }

    selectLanguage() {
        const newLang = this.currentLang() === "en" ? "ar" : "en";
        this.translationService.setLanguage(newLang);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
